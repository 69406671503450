import React from 'react';
import './Blog.css';
import HomeFooter from '../Home/HomeFooter';
import { Link } from 'react-router-dom';
import BlogBox from './BlogBox';
import HomePageHeader from '../Home/HomePageHeader';

const Blogs = () => {
    const blogPosts = [
        { title: 'Decoding Acceptable Wait Times: A Guide to Managing Customer Expectations in Restaurant Lineups and Increasing Customer Satisfaction with PlaceinQ', 
            summary: 'In the busy world of dining out, making customers happy is super important. One big part of that is how long they have to wait to get a table at a restaurant...', 
            link: '/blogs/decoding-acceptable-wait-times',
            imgsrc: '/Blogs/Decoding-acceptable-wait-times/header-img.png'},

        { title: 'Introducing PlaceinQ: Enhancing Queue Management with Technology', 
            summary: 'PlaceinQ offers an efficient solution to optimize wait times and increase customer satisfaction. Here\'s how PlaceinQ can address the challenges of managing restaurant lineups...', 
            link: '/blogs/introducing-placeinq',
            imgsrc: '/Blogs/Introducing-placeinq/header-img.png'},

        { title: 'Top Tips for Managing Restaurant Waitlists During Peak Hours', 
            summary: 'Peak hours can be challenging for restaurants, with long wait times and impatient customers testing the limits of queue management. Implementing effective strategies during these busy periods...', 
            link: '/blogs/managing-restaurant-waitlists-during-peak-hours',
            imgsrc: '/Blogs/managing-restaurant-waitlists-during-peak-hours/header-img.png' },

        { title: 'Boosting Restaurant Profitability with PlaceinQ', 
            summary: 'PlaceinQ, a virtual queue system, is revolutionizing the way restaurants manage waitlists during peak hours. By streamlining operations, reducing wait times, and enhancing the customer experience...', 
            link: '/blogs/boosting-restaurant-profitability-with-placeinq',
            imgsrc: '/Blogs/Boosting-restaurant-profitbility-with-placeinq/header-img.png' },

      ];

  return (
    <div>
        <HomePageHeader/>
        <div className='Blogs-container'>
          {blogPosts.map(blog => (
            <BlogBox key={blog.title} title={blog.title} summary={blog.summary} link={blog.link} imgsrc={blog.imgsrc} />
          ))}
        </div>
        <HomeFooter/>
    </div>
  );
};

export default Blogs;
