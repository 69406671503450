import React, { useState, useEffect } from 'react';

const FormattedInput = ({ value, onChange, step, inputRef }) => {
  const [inputValue, setInputValue] = useState('');
  // const tempRef = useRef(null);

  // useEffect(() => {
  //   if (step===2 && tempRef.current) {
  //     tempRef.current.focus();
  //   }
  // }, [step])

  useEffect(() => {
    setInputValue(formatPhoneNumber(value)); // Format initial value if needed
  }, [value]);

  const handleInputChange = (e) => {
    const formattedInput = formatPhoneNumber(e.target.value);
    setInputValue(formattedInput);
    onChange(formattedInput); // You might want to store raw numbers instead depending on your needs
  };

  // Function to format the phone number
  const formatPhoneNumber = (value) => {
    const numbers = value.replace(/[^\d]/g, ''); // Strip all non-digits
    const char = {0:'(', 3:') ', 6:' '};
    let phoneNumber = '';
    for (let i = 0; i < numbers.length; i++) {
      phoneNumber += (char[i] || '') + numbers[i];
    }
    return phoneNumber;
  };

//   useEffect(() => {
//     console.log(inputRef.current)
//     if (inputRef.current) {
//       inputRef.current.focus();
//     }
//   }, []);

  return (
    <input
      ref={inputRef}
      type="tel"
      inputMode="numeric"
      value={inputValue}
      onChange={handleInputChange}
      className="customerspage-getinfo-input-normal-input"
      maxLength="14"
    //   placeholder="(###) ### ####"
    />
  );
};

export default FormattedInput;
