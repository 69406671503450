
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import React, { useState, useEffect } from 'react';
import './bd-wizard.css'; 
import './CustomersPage.css';
import { useParams, useNavigate } from 'react-router-dom';
import { API_URL } from '../../EnvironmentVariables';
import Swal from 'sweetalert2'
import CustomerFooter from "./CustomerFooter";
import LoadingSpinner from './LoadingSpinner'; // Adjust the path as necessary
import Joyride, {STATUSm} from "react-joyride";

const fetchRestaurant = async (restaurant_name) => { 
  try {
    const url = `${API_URL}/api/get-restaurant-by-name/`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'restaurant_name': restaurant_name,
      })
    });
    if (!response.ok) throw new Error('Restaurant fetch failed');
    return await response.json(); // Assuming the API returns JSON data
  } catch (error) {
    console.error(error);
    return null;
  }
}

export default function BdWizardDemo() {

  const [{run, steps}, setSteps] = useState({
    run: true,
    steps: [
      {
        content: <h4>Thanks for visiting placeinQ. Let's begin the journey!</h4>,
        locale: { skip: <strong>SKIP</strong>},
        palcement: "center",
        target: "body"
      },
      {
        content: <h2>Here is the first step</h2>,
        locale: { skip: <strong>SKIP</strong>},
        palcement: "bottom",
        target: "#joyride-step-1",
        title: "First step"
      },
    ]
  });


  const history = useNavigate();

  const [customerName, setCustomerName] = useState('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [numberOfGuests, setNumberOfGuests] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Track loading state


  const increment = () => {
    setNumberOfGuests((prevNumber) => prevNumber + 1);
  };

  const decrement = () => {
    setNumberOfGuests((prevNumber) => prevNumber > 1 ? prevNumber - 1 : 1);
  };

  const handleChange = (e) => {
    let value = parseInt(e.target.value, 10);
    value = isNaN(value) ? 1 : value < 1 ? 1 : value;
    setNumberOfGuests(value);
  };


  const handleComplete = () => {
    const create_demo_restaurant = async () => {
        const url = `${API_URL}/api/demoregister/`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log("Response from adding customer:", response);
            return {
              restaurantName: data.restaurant_name, 
              restaurantEmail: data.restaurant_email // Adjust this key according to your actual API response
            };

        } catch (error) {
            console.error('Fetch error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Oops..',
                text: 'Failed to create demo restaurant',
              });
              return null;
        }
    };


    const addCustomer = async (restaurantName) => {
      const url = `${API_URL}/api/addcustomer/`;

      try {
          const response = await fetch(url, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  'name': customerName === ''?'there':customerName,
                  'phone_number': customerPhoneNumber,
                  'guests_number': numberOfGuests,
                  'restaurant_name': restaurantName,
              })
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          console.log("Customer added successfully");

      } catch (error) {
          console.error('Fetch error:', error);
          Swal.fire({
              icon: 'error',
              title: 'Adding to the waitlist failed',
              text: 'There was an error adding you to the queue.',
            });
      }
  };

  const handleSignInResponse = async (res, dashbaord) => {
    if(!res.token || res.token === undefined){
        Swal.fire({
            icon: 'error',
            title: 'Login Failed',
            text: 'Wrong email address or password'
          });
    }
    else {
        localStorage.setItem('token', res.token);
        // if(dashbaord)
        //     history("/dashboard")
        // else
        //     history("/customers")
    }
  }


  const signIn = (email, password, dashboard = true) => {
      const url = `${API_URL}/api/token/`;
      fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              'email': email,
              'password': password
          })
      })
      .then(resp => resp.json())
      .then(res => {
          handleSignInResponse(res, dashboard);
          setShowModal(true);
          setTimeout(() => {
              setShowModal(false);
              history('/demo/dashboard');
          }, 5000);
      })
      .catch(errors => console.log(errors));
  };

  create_demo_restaurant().then(({restaurantName, restaurantEmail}) => {
    if (restaurantName && restaurantEmail) {
        addCustomer(restaurantName).then(() => {
            // Use the restaurant email and a predefined or input password for signIn
            const restaurantPassword = "12345678"; // Define or get this password accordingly
            signIn(restaurantEmail, restaurantPassword);
        });
      }
  });

};

const handleOk = () => {
    setShowModal(false);
    history('/demo/dashboard');

};
  const checkValidateTab = () => {
    if (customerPhoneNumber === "" || customerPhoneNumber.length !== 10) {
      return false;
    }
    return true;

  };
  const errorMessages = () => {
    alert("Please provide a correct phone number");
  };
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      backgroundColor: 'black',
      border: 'none',
      color: 'white',
      padding: '10px 20px',
      margin: '0 5px',
      cursor: 'pointer',
      fontSize: '30px',
    },
    input: {
      textAlign: 'center',
      fontSize: '30px',
      padding: '10px',
      margin: '0 5px',
      border: '1px solid #ced4da',
      borderRadius: '5px',
      width: '30%', // Set a fixed width for the input
      minWidth: '180px'
    },
    

    modal: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
        width: '360px', /* Adjust as necessary */
        // z-index: 1000,
        textAlign: 'center',
    }
  };

  function guestsIcon() {
    return (
      <img
        src="/guests-number.png"
        alt="vitejs"
        width="34"
        height="34"
      />
    );
  }
  function phoneIcon() {
    return (
      <img
        src="/phone-call.svg"
        alt="vitejs"
        width="24"
        height="24"
      />
    );
  }
  function nameIcon() {
    return (
      <img
        src="/name-svgrepo-com.svg"
        alt="vitejs"
        width="28"
        height="28"
      />
    );
  }



  return (
    
    <div>
        {isLoading ? <LoadingSpinner /> : (

        <div >
        {/* <img src="https://logowik.com/content/uploads/images/restaurant9491.logowik.com.webp" alt="Restaurant Logo" className="restaurant-logo" /> */}
        {/* <h1 className="restaurant-name">Welcome to {user?.restaurant_name}</h1> */}
        {/* <h1>{restaurantName}</h1> */}
        {/* <Joyride
          disableBeacon
          run={true}
          steps={steps}
          continuous={true}
          scrollToFirstStep
          showSkipButton
          showProgress
        /> */}
        {!showModal&&
            <FormWizard 
            color="rgba(89, 27, 176)" 
            onComplete={handleComplete} >
                
                <FormWizard.TabContent title="Step 1" icon={guestsIcon()} >
                
                <h3>Party Size:</h3>
                <br />
                <button style={styles.button} onClick={decrement}>-</button>
                    <input
                        type="text"
                        value={numberOfGuests}
                        onChange={handleChange}
                        style={styles.input}
                        min="1"
                    />
                <button id={`joyride-step-${1}`} style={styles.button} onClick={increment}>+</button>
                <br />

                </FormWizard.TabContent>

                <FormWizard.TabContent 
                title="Step 2"
                icon={phoneIcon()}
                //   isValid={checkValidateTab()}
                validationError={errorMessages}>
                {/* <h2>Step: 2/3</h2> */}
                <h3>Phone Number:</h3>
                <br />
                <input
                    // className="form-control"
                    type="number" 
                    pattern="[0-9]*" 
                    inputmode="numeric"
                    value={customerPhoneNumber}
                    style={styles.input}
                    onChange={(e) => setCustomerPhoneNumber(e.target.value)}/> 
                    <br />

                </FormWizard.TabContent>
                <FormWizard.TabContent title="Step 3" icon={nameIcon()} isValid={checkValidateTab()} validationError={errorMessages}>
                <h3>Name:</h3>
                <br />
                <input
                    type="text"
                    value={customerName}
                    style={styles.input}
                    onChange={(e) => setCustomerName(e.target.value)}/> 
                <br />

                </FormWizard.TabContent>
            </FormWizard>
        }
        {showModal && (
                <div style={styles.modal}>
                    <span className="check-mark">✔️</span>
                    <h1>Thank you!</h1>
                    <p>Now let's manage the customers in your restaurant's waitlist</p>                
                    <button className="modal-ok" onClick={handleOk}>OK</button>
                </div>
        )}
        </div>
                )}
        <CustomerFooter />
    </div>
  );
}
