import React from 'react';
import './Blog.css';
import HomeFooter from '../Home/HomeFooter';
import { Link } from 'react-router-dom';
import HomePageHeader from '../Home/HomePageHeader';

const IntroducingPlaceinq = () => {
  return (
    <div className="aboutUsContainer">
        <HomePageHeader />
        <div className="blog-header">
            <h1>Introducing PlaceinQ: Enhancing Queue Management with Technology</h1>
        </div>
        <div className='blog-header-image'>
            <img src="/Blogs/Introducing-placeinq/header-img.png" alt="Header image" />                        
        </div>
        <div className='blog-content'>
            <p>
                PlaceinQ offers an efficient solution to optimize wait times and increase customer satisfaction. 
                Here's how PlaceinQ can address the challenges of managing restaurant lineups:
            </p>

            <h5>1. Virtual Queue Management:</h5>
            <p className='list-items'>
                PlaceinQ allows customers to join a virtual waitlist right from their phones, 
                whether they're at the restaurant, en route, or even before stepping out from home, 
                reducing the need for physical lineups.
            </p>
            <h5>2. Reduce perceived wait times:</h5>
            <p className='list-items'>
                When customers aren't physically standing in line, they perceive the wait time as shorter, 
                even if the actual time spent waiting is the same.
            </p>

            <h5>3.Enhance customer experience:</h5>
            <p className='list-items'>
                PlaceinQ enables customers to spend their waiting time more enjoyably, 
                exploring nearby shops or relaxing in a comfortable waiting area.            
            </p>

            <h5>4.Increased operational efficiency:</h5>
            <p className='list-items'>
                Restaurants can better manage their queues, reduce walkaways, and provide wait time estimates,
                 leading to smoother operations and increased table turnover.           
            </p>

            <p>
                In the competitive landscape of restaurant dining, managing acceptable wait times is 
                critical to ensuring customer satisfaction and loyalty. By understanding customer 
                expectations and leveraging innovative solutions like PlaceinQ, restaurants can 
                optimize queue management and deliver exceptional dining experiences. 
                With PlaceinQ, establishments can strike the perfect balance between efficiency and 
                customer satisfaction, ultimately driving growth and success in the ever-evolving 
                restaurant industry.
            </p>
            
            <div className='start-using-placeinq'>
                <Link to='/signup'>
                    <h2>Start using PlaceinQ today!</h2>
                </Link>
            </div>
        </div>
        <HomeFooter/>
    </div>
  );
};

export default IntroducingPlaceinq;
