import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';

import { faInstagram, faXTwitter, faLinkedin  } from '@fortawesome/free-brands-svg-icons';

const HomeFooter = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smooth scrolling
        });
    };

    return (
        <div className='bg-placeinq w-full'>
        <footer className=" text-white text-center relative py-4 px-12 max-w-screen-xl my-0 mx-auto">
            <div className="flex flex-col md:flex-row justify-between align-center gap-6">
                <div className="cursor-pointer" onClick={scrollToTop}>
                    <img className="h-12" src="/new-logo-white.png" alt="Logo" />
                </div>
                <div className="md:max-w-60 md:mt-3">
                    <h4 className='text-left font-bold text-2xl mb-2'>Contact Information</h4>
                    <Link to="tel:7788063823" >
                        <p className='text-left text-white'>Phone: +1 (778) 806-3823</p>
                    </Link>
                    <p className='text-left'>Email: hi@placeinq.com</p>
                    <p className='text-left'>Head Office Address: 321 Water St #501, Vancouver, BC V6B 1B8</p>

                </div>
                <div className="md:mt-3">
                    <h4 className='text-left font-bold text-2xl mb-2'>About</h4>

                    <a href="/blogs" style={{"color": "white"}}>
                        <p className='text-left'>Blogs</p>
                    </a>
                </div>
                <div className=" md:mt-3">
                    <h4 className='text-left font-bold text-2xl mb-2'>Follow Us</h4>
                    <div className='flex gap-4'>
                        <a className='text-white' href="https://instagram.com/placeinq" target="_blank" rel="noopener noreferrer" >
                            <FontAwesomeIcon  icon={faInstagram}/>
                        </a>
                        
                        <a className='text-white' href="https://x.com/placeinq" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faXTwitter} />
                        </a>
                    </div>

                </div>
            </div>
            <div className='pt-4'>
                <div className="footer-botto pt-3 border-t-[1px] border-slate-500">
                    <p>&copy; {new Date().getFullYear()} placeinQ. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
        </div>

    );
}

export default HomeFooter;
