import React, { useEffect } from 'react';
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();
export default function HomeImageSlider() {

    return (
        <>
        <main>
        <swiper-container style={
            {
            "--swiper-navigation-color": "black", 
            "--swiper-pagination-color": "#fff",
            
            }
        } 
        pagination-clickable="true" 
        scrollbar-clickable="true"
        // navigation="true" 
        autoplay-delay="3000"
        className="mySwiper">
                <swiper-slide lazy="true">
                    <img src="/homepage-images/tablet-customers/partysize.png" loading="lazy" alt="" />
                </swiper-slide>
                    
                <swiper-slide lazy="true">
                    <img loading="lazy" src="/homepage-images/tablet-customers/phonenumber.png" alt="" />
                </swiper-slide>
    
                <swiper-slide lazy="true">
                    <img loading="lazy" src="/homepage-images/tablet-customers/name.png" alt="" />
                </swiper-slide>
    
                <swiper-slide lazy="true">
                    <img loading="lazy" src="/homepage-images/tablet-customers/thanks.png" alt="" />
                </swiper-slide>

            </swiper-container>
        </main>
        </>
    )
    }