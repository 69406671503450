import React, { useState, useEffect } from 'react';
import './bd-wizard.css'; 
import './WaitlistForCustomers.css'
import { useParams, useNavigate } from 'react-router-dom';
import { API_URL } from '../../EnvironmentVariables';
import LoadingSpinner from './LoadingSpinner'; // Adjust the path as necessary
import CustomerCardForPublicWaitlist from './CustomerCardForPublicWaitlist';

const fetchRestaurant = async (restaurant_name) => { 
  try {
    const url = `${API_URL}/api/get-restaurant-by-name/`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'restaurant_name': restaurant_name,
      })
    });
    if (!response.ok) throw new Error('Restaurant fetch failed');
    return await response.json(); // Assuming the API returns JSON data
  } catch (error) {
    console.error(error);
    return null;
  }
}

const fetchWaitlistForCustomer = async (customer_id, restaurant_name) => { 
    try {
      const url = `${API_URL}/api/get-waitlist-for-customer/`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'customer_id': customer_id,
          'restaurant_name': restaurant_name,
        })
      });
      if (response.status === 410) {
        // Throw a specific error for 410 to handle it distinctly
        throw new Error('410');
      }
      if (!response.ok) {
        throw new Error('Customer fetch failed');
      }
      return await response.json(); 
    } catch (error) {

      if (error.message === '410') {
        return { error: '410' }; // Return an object with error detail
      } else {
        throw error; // Re-throw other errors to handle them outside
      }

    }
  }

export default function CancelReservation() {
const params = useParams();
  const { restaurantName, customerID } = params;

  const history = useNavigate();

  const [showYouHaveCancelledModal, setShowYouHaveCancelledModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  const [restaurant_logo, setRestaurant_logo] = useState(''); 
  let [waitlist, setWaitlist] = useState([]);
  const [cancelModal, setCancelModal] = useState(true);

  useEffect(() => {

      const getRestaurantData = async () => {
        const restaurantData = await fetchRestaurant(restaurantName);
        if (!restaurantData) {
          history('/not-found');
          return;
        } else {
          try{
            setRestaurant_logo(restaurantData.restaurant_logo);
            const waitlistData = await fetchWaitlistForCustomer(customerID, restaurantName);
            if (waitlistData.error === '410') {
              setShowYouHaveCancelledModal(true);
              setCancelModal(false);
              setIsLoading(false);
            }else{
              setWaitlist(waitlistData);
              setIsLoading(false);
            }
          } catch (error){
            history(`/${restaurantName}`);
          }

        }
      };
  
      getRestaurantData();
  }, [restaurantName, history]);

  const CancelReservation = async() => {
    const url = `${API_URL}/api/cancelReservation/`;
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'customer_id': customerID,
            })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        window.location.reload();

    } catch (error) {
        console.error('Fetch error:', error);
    }
    CloseCancelModal()
};



  const OpenCancelModal = () => {
      setCancelModal(true);
  }
  const CloseCancelModal = () => {
    setCancelModal(false);
}
  const getInWaitlist = () => {
    history(`/${restaurantName}`);
  }
  return (

    <div>
        {isLoading ? <LoadingSpinner /> : (

      <div>

          <div>
            <img src={restaurant_logo==='https://placeinq-bucket.s3.amazonaws.com/'?"/Q-logo.png": restaurant_logo} alt="Restaurant Logo" className="restaurant-logo"/>
            <h1>{restaurantName}</h1>
            <button className='delete-all-btn' onClick={OpenCancelModal}>Cancel reservation</button>

          </div>


          {cancelModal && (
                <div className="deleteallmodal">
                    <h1>Are you sure you want to cancel your reservation?</h1>
                    <button className="modal-ok" onClick={CancelReservation}>Yes</button>
                    <button className="modal-cancel" onClick={CloseCancelModal}>No</button>
                </div>
            )}
            
            {showYouHaveCancelledModal &&
                <div className="deleteallmodal">
                    <h1>You have cancelled your reservation</h1>
                    <button className="modal-ok" onClick={getInWaitlist}>Get in the waitlist</button>
                </div>
            }
      </div>
        )}
      </div>
    
)
}