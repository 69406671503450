import React from 'react';
import HomeFooter from '../Home/HomeFooter';
import { Link, useNavigate } from 'react-router-dom';
import HomePageHeader from '../Home/HomePageHeader';

const PricingPage = () => {
  return (
    <div>
        <HomePageHeader/>
        <div className='flex flex-col items-center gap-4 px-12 py-12'>

        <div className="flex flex-col md:flex-row items-center justify-center gap-4 w-full">
            <div className="flex flex-col justify-between border-[1.5px] border-placeinq rounded-xl p-3 w-full md:w-1/3 max-w-[340px] h-52 font-main">
                <h3 className='font-bold text-placeinq'>Starter Plan</h3>
                <p>$55.00/month</p>
                <p className='mt-2'>3000 notification / month</p>
                <Link to="/signup">
                    <button className='placeinq-button mt-4  w-[120px]'>Sign Up</button>
                </Link>
            </div>
            <div className="flex flex-col justify-between border-[1.5px] border-placeinq rounded-xl p-3 w-full md:w-1/3 max-w-[340px] h-52 font-main">
                <h3 className='font-bold text-placeinq'>Standard Plan</h3>
                <p>$85.00/month</p>
                <p className='mt-2'>5000 notification / month</p>
                <Link to="/signup">
                    <button className='placeinq-button mt-4 w-[120px]'>Sign Up</button>
                </Link>
            </div>

            <div className="flex flex-col justify-between border-[1.5px] border-placeinq rounded-xl p-3 w-full md:w-1/3 max-w-[340px] h-52 font-main">
                <h3 className='font-bold text-placeinq'>Custom Plan</h3>
                <p>Contact us</p>
                <p className='mt-2'>custom notification / month</p>
                <Link to="/contact-us">
                    <button className='placeinq-button mt-4 w-[120px]'>Contact Us</button>
                </Link>
            </div>

        </div>
        <div className='flex flex-col md:flex-row items-center justify-between gap-4 w-full max-w-[340px] md:max-w-[1052px] border-[1.5px] border-placeinq rounded-xl p-3'>
            <p className="w-full md:w-1/2 text-3xl font-main">Purchase a tablet for $200.00</p>
            <img className="w-full md:w-1/2 md:w-1/2" src="/homepage-images/pricing/tabletforsale.png" alt="Tablet image" />
        </div>
        </div>

        <HomeFooter/>
    </div>
  );
};

export default PricingPage;
