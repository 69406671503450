import React from 'react';
import './CustomerFooter.css'; // Assuming the CSS is saved in Footer.css

const CustomerFooter = () => {
  return (
    <div>
      <p className='Customerfooter'>THIS SERVICE IS BROUGHT TO YOU BY PLACEINQ</p>
    </div>
  );
};

export default CustomerFooter;
