import { API_URL } from '../../EnvironmentVariables';
// import './CustomerCard.css';
import './CustomerCardForPublicWaitlist.css'
import React, { useState, useEffect } from 'react';

function CustomerCardForPublicWaitlist({isBarbershop, customer, mode}) {
    const [time, setTime] = useState(new Date());
    const [created_at, setCreated_at] = useState(); 
    const [updated_at, setUpdated_at] = useState(); 

    const [waitTime, setWaitTime] = useState(); 

    useEffect(() => {
        const created_at_date = new Date(customer.created_at);
        const updated_at_date = new Date(customer.updated_at);
        setCreated_at(created_at_date.toLocaleString([], { hour: '2-digit', minute: '2-digit' }));
        setUpdated_at(updated_at_date.toLocaleString([], { hour: '2-digit', minute: '2-digit' }));

        const timerId = setInterval(() => {
            setTime(new Date());
            setUpdated_at(updated_at_date.toLocaleString([], { hour: '2-digit', minute: '2-digit' }));
        }, 60000);

        return () => clearInterval(timerId);
    }, []);





    return (
    <tr key={customer.id} className={`customer-row-publicwaitlist ${customer.is_target_customer===true?'target':''}`} style={{ backgroundColor: customer.is_target_customer ? '#189a5085' : '#f5f5f5' }}>
    <td data-label="name">{customer.name || 'Guest'}</td>
    <td style={{"display":`${isBarbershop?'none':''}`}} data-label="#guests">{customer.guests_number}</td>

    <td data-label="waiting time" className='waiting-time'>
        
    {   
        customer.waiting_time === 0 ? 'less than 1 min': 
        (customer.waiting_time === 1 ? '1 min':
        customer.waiting_time + ' mins')
        }</td>

</tr>

);
}

export default CustomerCardForPublicWaitlist;
