import { API_URL } from '../../EnvironmentVariables';
import CustomerCard from './CustomerCard';
import './RestaurantDashboard.css'; // Your CSS file for styling
import './DemoRestaurantDashboard.css'; // Your CSS file for styling
import React, { useContext, useState, useEffect  } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { GetUser } from '../../App';
import LoadingSpinner from '../customers/LoadingSpinner';
import Settings from './Settings';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import "shepherd.js/dist/css/shepherd.css";
import Shepherd from 'shepherd.js';
// import { useTour } from 'react-shepherd';


function DemoRestaurantDashboard() {
    let [customers, setCustomers] = useState([]);
    const [user, setUser] = useState(null); // Assuming you want to store the user

    const [activeTab, setActiveTab] = useState('customers'); // 'plan' or 'customers'
    const [showGotoCustomersPageModal, setShowGotoCustomersPageModal] = useState(false);
    const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
    const [showActivateAccountModal, setShowActivateAccountModal] = useState(false);
    const [confirmPassword,setConfirmPassword] = useState('');
    const [onboardingPercent, setOnboardingPercent] = useState(0);


    const [step, setStep] = useState(1); // Manage current step
    const [restaurantName, setRestaurantName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Track loading state
    const location = useLocation();

    const subscriptionPlans = [
        { id: 1, texts: 100, price: 29.99 },
        { id: 2, texts: 200, price: 49.99 },
        { id: 3, texts: 500, price: 79.99 }
    ];
    
    const history = useNavigate();

    const tourOptions = {
        defaultStepOptions: {
          cancelIcon: {
            enabled: true
          }
        },
        useModalOverlay: true
      };
      
    const buttonConfig = [
        {
            action() {
                return this.back();
            },
            classes: 'shepherd-button-secondary',
            text: 'Back'
        },
        {
            action() {
                return this.next();
            },
            text: 'Next'
        }
      ];

    
      const tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
          classes: 'shadow-md',
          cancelIcon: {
            enabled: true
            },
          scrollTo: true
        }
      });

    tour.addStep({
        id: '1',
        text: 'This is your waitlist',
        attachTo: {
          element: '.customer-table',
          on: 'top-start'
        },
        classes: 'example-step-extra-class',
        buttons: [
          {
            text: 'Next',
            action: tour.next
          }
        ]
      });

      tour.addStep({
        id: '2',
        text: 'This is the latest customer added to the waitlist',
        attachTo: {
          element: '.customer-row',
          on: 'bottom'
        },
        classes: 'example-step-extra-class',
        buttons: buttonConfig
      });

      tour.addStep({
        id: '3',
        text: 'You can monitor their waiting time',
        attachTo: {
          element: '.waiting-time',
          on: 'bottom'
        },
        classes: 'example-step-extra-class',
        buttons: buttonConfig
      });

      tour.addStep({
        id: '4',
        text: 'You can also monitor their status: waiting, invited, ...',
        attachTo: {
          element: '.customer-status',
          on: 'bottom'
        },
        classes: 'example-step-extra-class',
        buttons: buttonConfig
      });
      tour.addStep({
        id: '5',
        text: 'Now let\'s try sending an invitation sms to the customer by clicking on the "Invite" button.',
        attachTo: {
          element: '.sms-button',
          on: 'bottom'
        },
        classes: 'example-step-extra-class',
        buttons: buttonConfig
      });

      tour.addStep({
        id: '6',
        text: 'You can always delete a customer from the waitlist by cicking on the "Delete" button.',
        attachTo: {
          element: '.action-buttons',
          on: 'bottom'
        },
        classes: 'example-step-extra-class',
        buttons: buttonConfig
      });

      tour.addStep({
        id: '7',
        text: 'Great! now let\'s activate your account by clicking on this button.',
        attachTo: {
          element: '.activate-account-btn',
          on: 'left'
        },
        classes: 'example-step-extra-class',
        buttons: [
            {
                action() {
                    return this.back();
                },
                classes: 'shepherd-button-secondary',
                text: 'Back'
            },
            {
                action() {
                    return OpenActivateAccountModal();
                },
                text: 'Activate account'
            }
          ]
      });
    //   OpenActivateAccountModal
    useEffect( () => {
        let uid;

        const fetchUser = async () => {
            const fetchedUser = await GetUser();

            if (fetchedUser === null) 
                history('/login');
            else{
                uid = fetchedUser.id;
                get_customers(uid); 
                setUser(fetchedUser);
                setIsLoading(false);
            }        
        };
        fetchUser();
        const intervalId = setInterval(() => get_customers(uid), 1000); // 5000 ms = 5 seconds
        return () => clearInterval(intervalId);        

    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (tour) {
                tour.start();
            }
        }, 3000);
      }, []); 

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const goToCustomersPage = () => {
        const firstSegment = location.pathname.split('/')[0];
        window.open(`${firstSegment}/${user.restaurant_name}`, '_blank', 'noopener,noreferrer');
    };

    const handleGotoCustomersPageOk = () => {
        setShowGotoCustomersPageModal(false);
        history('/customers');
    }

    const handleGotoCustomersPageCancel = () => {
        setShowGotoCustomersPageModal(false);
    }

    const Logout = () => {
        localStorage.removeItem("token")
        const url = `${API_URL}/api/logout/`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => {
            if(res.ok){
                history('/login');
            }
        })
        .catch(errors => console.log(errors));

    };

    
    const OpenActivateAccountModal = () => {
        if(tour){
            if(tour.isActive())
                tour.complete()
        }
        // if tour.getCurrentStep()
        setShowActivateAccountModal(true);
    }

    const OpenDeleteAllCustomersModal = () => {
        setShowDeleteAllModal(true);
    }

    const CloseDeleteAllCustomersModal = () => {
        setShowDeleteAllModal(false);
    }
    async function get_customers(uid) {
        const loadCustomers = async (uid) => {
            const url = `${API_URL}/api/get-customers/`;
            try {
                const response = await fetch(url, {
                    credentials: 'include',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        'restaurant_id': uid,
                    })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setCustomers(data);

            } catch (error) {
                console.error('Fetch error:', error);
            }
        };

        loadCustomers(uid);
    }   


    const deleteallcustomers = async() => {
        const url = `${API_URL}/api/deleteallcustomers/`;
        
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    'restaurant_id': user.id,
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // window.location.reload();

        } catch (error) {
            console.error('Fetch error:', error);
        }
        CloseDeleteAllCustomersModal();
    };


    const handlePurchase = (planId) => {
        console.log("Purchased plan:", planId);
    };

    const nextStep = () => {
        setStep(step + 1);
    };
    
    // Function to go back to the previous step
    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };
    
    // Function to close the modal and reset everything
    const closeActivateAccountModal = () => {
        setShowActivateAccountModal(false);
        setStep(1); // Reset step to 1
        // Reset all the form fields
        setRestaurantName('');
        setEmail('');
        setPassword('');
    };

    const handleSubmitActivateAccount = async() => {
        console.log(restaurantName, email, password);

        const url = `${API_URL}/api/me/`;
        try {
            const response = await fetch(url, {
                credentials: 'include',
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    'email': email,
                    'restaurant_name': restaurantName,
                    'password': password
                })
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            // Assuming you want to do something with the response data
            // const data = await response.json();
            // console.log('Success:', data);
            history('/dashboard');

            // Optional: Show success message to the user
    
        } catch (error) {
            console.error('There was an error!', error);
            // Optional: Show error message to the user
        }
    
        // Close modal after submission or error
        closeActivateAccountModal();
    };

    return (
        <div>
            {/* <ShepherdTour steps={steps} tourOptions={tourOptions}> */}

            {isLoading ? <LoadingSpinner /> : (

            <div className="dashboard-container">
                <div className={`sidebar ${isSidebarOpen ? 'active' : ''}`}>
                    <Link to="/">
                        placeinQ
                    </Link>
                    <br/>
                    <button className="to-customers-page" onClick={goToCustomersPage}>
                            Open Customers Page
                    </button>

                    <img src={user.restaurant_logo?user.restaurant_logo:"https://logowik.com/content/uploads/images/restaurant9491.logowik.com.webp"} alt="Restaurant Logo" className="restaurant-logo" />
                    <button onClick={() => setActiveTab('customers')}>Wait List</button>
                    {/* <button onClick={() => setActiveTab('plan')}>Plans</button> */}
                    <button onClick={() => setActiveTab('settings')}>Settings</button>
                    <button className="logout-button" onClick={Logout}>Logout</button>
                </div>

                <div className="hamburger-icon" onClick={toggleSidebar}>
                    {isSidebarOpen ? 'X' : '☰'}
                </div>

                <div className="main-content">

                    {activeTab === 'customers' && (
                        
                        <div className="customer-list">
                            <button className='activate-account-btn' onClick={OpenActivateAccountModal}>Activate account</button>                            
                            <h1>Customers Wait List</h1>

                            <button className='delete-all-btn' onClick={OpenDeleteAllCustomersModal}>Delete all customers</button>
                            <table className="customer-table">
                                <thead>
                                    <tr>
                                    <th>name</th>
                                    <th>#guests</th>
                                    <th>waiting time</th>
                                    <th>status</th>
                                    <th>phone number</th>
                                    <th>actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customers.map(customer => (
                                        <CustomerCard
                                            key={customer.id}
                                            customer={customer}
                                            mode='demo'
                                        />
                                    ))}
                                </tbody>
                                </table>
                        </div>
                    )}
                    {activeTab === 'plan' && (

                        <div className="subscription-plans">
                            <h1>PlaceinQ Plans</h1>
                            <div className="plans-container">
                                {subscriptionPlans.map((plan) => (
                                    <div key={plan.id} className="plan">
                                        <p><strong>Texts:</strong> {plan.texts}</p>
                                        <p><strong>Price:</strong> ${plan.price}</p>
                                        <button onClick={() => handlePurchase(plan.id)}>Purchase</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {activeTab === 'settings' && (
                        <Settings
                        user={user}
                        />
                    )}

                    {showDeleteAllModal && (
                        <div className="deleteallmodal">
                            <h1>Are you sure you want to delete all the customers?</h1>
                            <button className="modal-ok" onClick={deleteallcustomers}>Yes</button>
                            <button className="modal-cancel" onClick={CloseDeleteAllCustomersModal}>Cancel</button>
                        </div>
                    )}

                    {showActivateAccountModal && (
                        <div className="activateaccountlmodal">
                            {step === 1 && (
                                <>
                                <h6>1/3</h6>
                                    <h1>Please enter your restaurant name:</h1>
                                    <input
                                        type="text"
                                        value={restaurantName}
                                        onChange={(e) => setRestaurantName(e.target.value)}
                                    />
                                    <div className="modal-action-buttons">
                                        <button className="modal-next" onClick={nextStep}>Next</button>
                                    </div>
                                </>
                            )}
                            {step === 2 && (
                                <>
                                    <h6>2/3</h6>
                                    <h1>Please enter your email address:</h1>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                <div className="modal-action-buttons">
                                    <button className="modal-next" onClick={nextStep}>Next</button>
                                    <button className="modal-back" onClick={prevStep}>Back</button>
                                </div>                                </>
                            )}
                            {step === 3 && (
                                <>
                                    <h6>3/3</h6>
                                    <h1>Please set a password:</h1>
                                    <input
                                        type="password"
                                        value={password}
                                        minLength={8}
                                        required={true}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />

                                    {/* <h1>Confirm password:</h1>
                                    <input 
                                        type='password' 
                                        minLength={8}
                                        required={true}
                                        value={confirmPassword} 
                                        onChange={e => setConfirmPassword(e.target.value)}
                                    /> */}

                                <div className="modal-action-buttons">
                                    <button className="activateaccountmodal-ok" onClick={handleSubmitActivateAccount}>Activate your placeinQ account</button>
                                    <button className="modal-back" onClick={prevStep}>Back</button>
                                </div>                                
                                </>
                            )}
                            <span className="activateaccountmodal-close" onClick={closeActivateAccountModal}>X</span>
                        </div>
                    )}

                </div>

            </div>
            )}
              {/* </ShepherdTour> */}
        </div>

    );
}

{/* <script src="/node_modules/popper.js/dist/umd/popper.min.js"></script>
<script src="/node_modules/shepherd.js/dist/js/shepherd.min.js"></script> */}

export default DemoRestaurantDashboard;
