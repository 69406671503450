import { API_URL } from '../../EnvironmentVariables';
import './DashboardWaitlistCustomer.css';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SmsIcon from '@mui/icons-material/Sms';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
function DeleteConfirmationModal({ isOpen, onClose, onConfirm, customerId, customerName }) {
    if (!isOpen) return null;
  
    return (
      <div className="confirmationModal">
          <h1>Are you sure you want to remove customer {customerName}?</h1>
          <button className="modal-ok red" onClick={() => onConfirm(customerId)}>Yes, Remove</button>
          <div className="modal-cancel" onClick={onClose}>Cancel</div>
      </div>
    );
  }

function ServiceConfirmationModal({ isOpen, onClose, onConfirm, customerId, customerName }) {
    if (!isOpen) return null;
  
    return (
      <div className="confirmationModal">
          <h1>Served {customerName} and want to remove them from the waitlist?</h1>
          <button className="modal-ok green" onClick={() => onConfirm(customerId)}>Yes</button>
          <div className="modal-cancel" onClick={onClose}>Cancel</div>
      </div>
    );
  }
function DashboardWaitlistCustomer({customer, mode, type, isBarbershop}) {
    const [time, setTime] = useState(new Date());
    const [created_at, setCreated_at] = useState(); 
    const [updated_at, setUpdated_at] = useState(); 
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isServiceModalOpen, setServiceModalOpen] = useState(false);

    const [waitTime, setWaitTime] = useState(); 

    useEffect(() => {
        const created_at_date = new Date(customer.created_at);
        const updated_at_date = new Date(customer.updated_at);
        setCreated_at(created_at_date.toLocaleString([], { hour: '2-digit', minute: '2-digit' }));
        setUpdated_at(updated_at_date.toLocaleString([], { hour: '2-digit', minute: '2-digit' }));

        const timerId = setInterval(() => {
            setTime(new Date());
            setUpdated_at(updated_at_date.toLocaleString([], { hour: '2-digit', minute: '2-digit' }));
        }, 60000);

        return () => clearInterval(timerId);
    }, []);


    function calculateTimeDifference(startTime, endTime) {
        const start = new Date(`1970-01-01T${startTime}Z`); // Assuming times are in HH:MM format
        const end = new Date(`1970-01-01T${endTime}Z`);
    
        const differenceInMilliseconds = end - start;
    
        const hours = Math.floor(differenceInMilliseconds / 3600000); // 3600000 milliseconds in an hour
        const minutes = Math.floor((differenceInMilliseconds % 3600000) / 60000); // 60000 milliseconds in a minute
        
        const timeDifference = { hours, minutes }
        setWaitTime({ hours, minutes })
        return timeDifference;
    }

    const handleSendSMS = (customerId) => {
        const sendSMS = async () => {
            const url = `${API_URL}/api/sendsms/`;

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        'customer_id': customerId,
                        'mode': mode
                    })
                });

                if (!response.ok) {
                    const data = await response.json();
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed to Send Message',
                      });
                    throw new Error('Network response was not ok');
                }
                
                message();

            } catch (error) {
                console.error('Fetch error:', error);
            }
        };
        sendSMS();
    };

    const handleDeleteCustomer = (customerId) => {

        const deleteCustomer = async () => {
            const url = `${API_URL}/api/deletecustomer/`;

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        'customer_id': customerId,
                    })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                // window.location.reload();

            } catch (error) {
                console.error('Fetch error:', error);
            }
        };
        deleteCustomer();
    };

    const handleServeCustomer = (customerId) => {

        const serveCustomer = async () => {
            const url = `${API_URL}/api/servecustomer/`;

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        'customer_id': customerId,
                    })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                // window.location.reload();

            } catch (error) {
                console.error('Fetch error:', error);
            }
        };
        serveCustomer();
    };


    const message = () =>{
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          Toast.fire({
            icon: "success",
            title: "Invitation Was Successfully Sent to Customer!"
          });
    }


    return (

        <div className='dashboardWaitlistCustomer'>
            <div className='dashboardWaitlistCustomer-info'>
                <div className='dashboardWaitlistCustomer-info-item'>
                    {customer.name || 'Guest'}
                </div>
                <div className='dashboardWaitlistCustomer-info-item' style={{ display: isBarbershop ? 'none' : '' }}>
                    {customer.guests_number}
                </div>
                <div className='dashboardWaitlistCustomer-info-item'
                        style={{ 
                            color: type=='completed' ? 'black' :
                            customer.waiting_time < 10 ? '' : '#d77300'
                        }}>
                    {   
                        customer.waiting_time === 0 ? 'less than 1 min': 
                        customer.waiting_time === 1 ? '1 min':
                        customer.waiting_time + ' mins'
                    }
                </div>  
                <div className='dashboardWaitlistCustomer-info-item' 
                        style={{ 
                            color: (customer.status === "Cancelled") ? '#d77300' : 
                            (customer.status === "Served") ? 'green' : 
                            (customer.status === "Removed") ? '#e60000' : 
                            (customer.invited < 10 || customer.created_at===customer.updated_at) ? '' : 
                            '#d77300'
                        }}    
                        >
                    {   
                        customer.status === "Cancelled" ? 'cancelled' :
                        customer.status === "Served" ? 'served' :
                        customer.status === "Removed" ? 'removed' :
                        customer.created_at===customer.updated_at ? 'waiting': 
                        customer.invited === 0 ? 'invited less than 1 min ago':
                        customer.invited === 1 ? 'invited 1 min ago':
                        'invited ' + customer.invited + ' mins ago'
                    }
                </div>  
                <div className='dashboardWaitlistCustomer-info-item'>
                    <span className="masked-phone">***{customer.phone_number.slice(-4)}</span>
                </div>  
            </div>

            <div className='dashboardWaitlistCustomer-phone-info'>
                <div className='dashboardWaitlistCustomer-phone-info-row'>
                    <div className='dashboardWaitlistCustomer-info-item'>
                        {customer.name || 'Guest'}
                    </div>
                    <div className='dashboardWaitlistCustomer-info-item'>
                        {customer.guests_number} people
                    </div>
                </div>

                <div className='dashboardWaitlistCustomer-phone-info-row'>
                    <div className='dashboardWaitlistCustomer-info-item'
                            style={{ 
                                color: type=='completed' ? 'black' :
                                customer.waiting_time < 10 ? '' : '#d77300'
                            }}>
                        {   
                            customer.waiting_time === 0 ? 'less than 1 min': 
                            customer.waiting_time === 1 ? '1 min':
                            customer.waiting_time + ' mins'
                        }
                    </div>  
                    <div className='dashboardWaitlistCustomer-info-item' 
                            style={{ 
                                color: (customer.status === "Cancelled") ? '#d77300' : 
                                (customer.status === "Served") ? 'green' : 
                                (customer.status === "Removed") ? '#e60000' : 
                                (customer.invited < 10 || customer.created_at===customer.updated_at) ? '' : 
                                '#d77300'
                            }}    
                            >
                        {   
                            customer.status === "Cancelled" ? 'cancelled' :
                            customer.status === "Served" ? 'served' :
                            customer.status === "Removed" ? 'removed' :
                            customer.created_at===customer.updated_at ? 'waiting': 
                            customer.invited === 0 ? 'invited less than 1 min ago':
                            customer.invited === 1 ? 'invited 1 min ago':
                            'invited ' + customer.invited + ' mins ago'
                        }
                    </div>  

                </div>

            </div>

            {type=='waiting'&&

                <div className='dashboardWaitlistCustomer-cta'>
                    <CheckCircleIcon className="dashboardWaitlistCustomer-cta-service" style={{"fontSize":"2.5rem"}}onClick={() => setServiceModalOpen(true)}/>
                    
                    <button className="dashboardWaitlistCustomer-cta-invite"
                        disabled={customer.status === "Cancelled" ? true: false}
                        style={{ backgroundColor: customer.status === 'waiting' ? '' : 'white', color:customer.status === 'waiting' ? '' : 'black'}}
                        onClick={() => handleSendSMS(customer.id)}>
                            {customer.status === 'waiting'? 'Invite' : 
                            customer.status === 'Cancelled'? '-' :
                            'Re-invite'}
                    </button>
                    
                    <CloseIcon className="dashboardWaitlistCustomer-cta-remove" style={{"fontSize":"2rem"}} onClick={() => setDeleteModalOpen(true)}/>

                </div>
            }

            <ServiceConfirmationModal 
                isOpen={isServiceModalOpen} 
                onClose={() => setServiceModalOpen(false)} 
                onConfirm={handleServeCustomer} 
                customerId={customer.id}
                customerName={customer.name}
            />

             <DeleteConfirmationModal 
                isOpen={isDeleteModalOpen} 
                onClose={() => setDeleteModalOpen(false)} 
                onConfirm={handleDeleteCustomer} 
                customerId={customer.id}
                customerName={customer.name}
                />
        </div>    

);
}

export default DashboardWaitlistCustomer;
