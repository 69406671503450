import React from 'react'
import './DashboardWaitlist.css'

import PersonIcon from '@mui/icons-material/Person';
import PortraitIcon from '@mui/icons-material/Portrait';
import GroupsIcon from '@mui/icons-material/Groups';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CustomerCard from './CustomerCard';
import {useLocation} from 'react-router-dom';
import { useState} from 'react';

import DashboardWaitlistCustomer from './DashboardWaitlistCustomer';

function DashboardWaitlist({customers, completed_customers, restaurant_name, isBarbershop}) {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('waiting-customers');

    const goToCustomersPage = () => {
        const firstSegment = location.pathname.split('/')[0];
        window.open(`${firstSegment}/${restaurant_name}`, '_blank', 'noopener,noreferrer');
    };

    return (

        <div className="dashboard-waitlist">
            <div className="dashboard-waitlist-headers">
                <div className={`dashboard-waitlist-header ${activeTab==="waiting-customers"?'active': ''}`} onClick={() => setActiveTab('waiting-customers')}>
                    Waitlist
                </div>
                <div className={`dashboard-waitlist-header ${activeTab==="completed-customers"?'active': ''}`} onClick={() => setActiveTab('completed-customers')}>
                    Completed Customers
                </div>
            </div>

            <div className='dashboard-waitlist-container'>
                <div className='dashboard-waitlist-container-header'>
                    <div className='dashboard-waitlist-container-header-columnnames'>
                        <div className='dashboard-waitlist-container-header-columnname'>
                            <PortraitIcon/>
                        </div>
                        <div className='dashboard-waitlist-container-header-columnname' style={{ display: isBarbershop ? 'none' : '' }}>
                            <GroupsIcon/>
                        </div>
                        <div className='dashboard-waitlist-container-header-columnname'>
                            <HourglassBottomIcon/>
                        </div>
                        <div className='dashboard-waitlist-container-header-columnname'>
                            <FindReplaceIcon/>
                        </div>
                        <div className='dashboard-waitlist-container-header-columnname'>
                            <LocalPhoneIcon/>
                        </div>
                    </div>
                    <div className='dashboard-waitlist-container-header-cta' onClick={goToCustomersPage}>
                        Add new customer
                    </div>
                </div>
                <div className="dashboard-waitlist-container-list">

                    {(activeTab==="waiting-customers"? customers : completed_customers).map(customer => (
                        <DashboardWaitlistCustomer
                            key={customer.id}
                            customer={customer}
                            mode='production'
                            type={activeTab==="waiting-customers"?'waiting':"completed"}
                            isBarbershop={isBarbershop}
                        />
                    ))}
                </div> 
            </div>

        </div>
    )
}

export default DashboardWaitlist;