import { API_URL } from '../../EnvironmentVariables';
import './Settings.css';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'

function Settings({user}) {

    const [showChangePasswordForm, setShowChangePasswordForm] = useState(true);
    const [showSetWaitTimeForm, setShowSetWaitTimeForm] = useState(false);
    const [showLogoForm, setShowLogoForm] = useState(false);
    const [showRestaurantNameForm, setShowRestaurantNameForm] = useState(false);
    const [shopName, setShopName] = useState('');

    const [password,setPassword] = useState('');
    const [newpassword,setNewpassword] = useState('');
    const [cnewpassword, setCnewpassword] = useState('');
    const [newwaittime, setNewwaitime] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
      };

    const handleShowChangePasswordForm = () => {
        setShowSetWaitTimeForm(false);
        setShowLogoForm(false);
        setShowRestaurantNameForm(false);
        setShowChangePasswordForm(true);
    };

    const handleShowSetWaitTimeForm = () => {
        setShowChangePasswordForm(false);
        setShowLogoForm(false);
        setShowRestaurantNameForm(false);
        setShowSetWaitTimeForm(true);
    };

    const handleShowSetLogoForm = () => {
        setShowChangePasswordForm(false);
        setShowSetWaitTimeForm(false);
        setShowRestaurantNameForm(false);
        setShowLogoForm(true);

    };

    const handleShowSetRestaurantNameForm = () => {
        setShowChangePasswordForm(false);
        setShowSetWaitTimeForm(false);
        setShowLogoForm(false);
        setShowRestaurantNameForm(true);

    };

    const handleSubmitChangePassword = async (event) => {
        event.preventDefault();
        
        try {
            const signinresult = await signIn(user.email, password);
            if (signinresult === true) {
                console.log('Sign-in successful, proceed with changing password.');
                const result = await setNewPassword(newpassword);
                Swal.fire({
                    icon: 'success',
                    title: 'Password successfully changed',
                  });
                setShowChangePasswordForm(false);
                // Implement your logic to change the password here
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'The current password provided is wrong',
                  });
            }
        } catch (error) {
            console.error('Error during sign-in:', error);
            return; // Early return to prevent further execution in case of error
        }
        setPassword('');
        setNewpassword('');
        setCnewpassword('');
    };

    const signIn = (email, password) => {
        const url = `${API_URL}/api/token/`;
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'email' : email,
                'password':  password
            })
        })
        .then(resp => {
            if (!resp.ok) {
                return false;
            }
            return true;
        })
    }


    const setNewPassword = (newpassword) => {
        const url = `${API_URL}/api/me/`;
        return fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                'password':  newpassword
            })
        })
        .then(resp => {
            if (!resp.ok) {
                return false;
            }
            return true;
        })
    }

    const handleSubmitChangeWaitTime = async (event) => {
        event.preventDefault();
        
        const url = `${API_URL}/api/setnewwaittime/`;
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                'wait_time':  newwaittime,
                'restaurant_id': user.id
            })
        })
        .then(resp => {
            if (!resp.ok) {
                Swal.fire({
                    icon: 'error',
                    title: 'Unable to change the waiting time',
                  });
            }
            Swal.fire({
                icon: 'success',
                title: `Waiting time successfully changed to ${newwaittime}`,
              });
            
            window.location.reload();
            setShowSetWaitTimeForm(false);
        })
        setNewwaitime('');
    };

    const handleSubmitChangeRestaurantName = async (event) => {
        const url = `${API_URL}/api/me/`;
        return fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                'restaurant_name': shopName
            })
        })
        .then(resp => {
            console.log("my response was")
            console.log(resp.ok)
            if (!resp.ok) {
                Swal.fire({
                    icon: 'error',
                    title: 'Unable to change the restaurant name',
                  });
            }
            Swal.fire({
                icon: 'success',
                title: `restaurant name successfully changed to ${shopName}`,
              });
            
            window.location.reload();
            setShowRestaurantNameForm(false);
        })
        setShopName('');
    }


    const handleSubmitChangeLogo = async (event) => {
        const url = `${API_URL}/api/me/`;
        let status = null;

        const data = new FormData();
        if (selectedFile) {
            data.append('restaurant_logo', selectedFile);
        }
        return fetch(url, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: data,

        })

        .then(response => {
            status = response.status;
            if (response.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: `restaurant logo successfully changed`,
                });
                return;
            }
            else{
                return response.json();
            }
        }
        )
        .then(resp => {
                if (status !== 201){
                Swal.fire({
                    icon: 'error',
                    title: 'Unable to change the restaurant logo',
                  });                
                }
            }
        )
    }

    return (            
        <div className='settings-container'>
            <div className='settings-header'>
                <p className={`${showChangePasswordForm ? 'select':''}`} onClick={handleShowChangePasswordForm}>Change Password</p>
                <p className={`${showLogoForm ? 'select':''}`} onClick={handleShowSetLogoForm}>Change logo</p>
                {/* <button onClick={handleShowSetRestaurantNameForm}>Change restaurant name</button> */}
                {/* <p className={`${showSetWaitTimeForm ? 'select':''}`} onClick={handleShowSetWaitTimeForm}>Set Wait Time</p> */}
            </div>
            
            <div className='settings-content'>
                {/* set password */}
                { showChangePasswordForm && (
                    <form className="settings-content-form" onSubmit={handleSubmitChangePassword}>
                        <div className="settings-content-form-row">
                            <label>Current Password</label>
                            <input 
                                type='password' 
                                value={password} 
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="settings-content-form-row">
                            <label>New Password</label>
                            <input 
                                type='password'
                                minLength={8}
                                required={true}
                                value={newpassword} 
                                onChange={e => setNewpassword(e.target.value)}
                            />

                        </div>
                        <div className="settings-content-form-row">
                            <label>Confirm New Password</label>
                            <input 
                                type='password'
                                minLength={8}
                                required={true}
                                value={cnewpassword} 
                                onChange={e => setCnewpassword(e.target.value)}
                            />
                            {(newpassword === cnewpassword || cnewpassword === '') ? null : <span className='input_alert'>Passwords do not match! <br/></span>}

                        </div>
                        <button 
                            disabled={!(password && cnewpassword && newpassword && (newpassword === cnewpassword) && (newpassword.length > 7) )}
                            type="submit">Change password</button>
                    </form>
                )}
                {/* set wait time */}
                {showSetWaitTimeForm && (
                    <form className="settings-content-form" onSubmit={handleSubmitChangeWaitTime}>
                        <div className="settings-content-form-row">
                            <label>Current Wait Time (Minutes)</label>
                            <div>
                                {user.wait_time}
                            </div>

                        </div>
                        <div className="settings-content-form-row">
                            <label>New Wait Time (Minutes)</label>
                            <br/>
                            <input 
                                type='number'
                                min="1"
                                required={true}
                                value={newwaittime} 
                                style={{"textAlign": "center"}}
                                onChange={e => setNewwaitime(e.target.value)}
                            />
                        </div>
                        <button 
                            // disabled={!(newwaittime)}
                            type="submit">Change wait time</button>
                    </form>

                )}
                {/* set logo */}
                {showLogoForm && (
                    <form className="settings-content-form" onSubmit={handleSubmitChangeLogo}>
                        <div className="settings-content-form-row">
                            <label>Current logo</label>
                            <img src={user.restaurant_logo?user.restaurant_logo:"/Q-logo.png"} alt="Restaurant Logo" className="restaurant-logo" />

                        </div>
                        <div className="settings-content-form-row">
                            <label>New logo</label>
                            <br/>
                            <input 
                                type="file" 
                                style={{"textAlign": "center"}}
                                onChange={handleFileChange} 
                            />

                        </div>
                        <button 
                            // disabled={!(newwaittime)}
                            type="submit">Change logo</button>
                    </form>

                )}
                {/* set restaurant name */}
                {/* {(settings_type==='restaurant-settings') && showRestaurantNameForm && (
                    <form className="settings-content-form" onSubmit={handleSubmitChangeRestaurantName}>
                        <div>
                            <label>Current restaurant name</label>
                            <h1>{user?.restaurant_name}</h1>
                        </div>
                        <div>
                            <label>New name</label>
                            <br/>
                            <input
                                type='text' 
                                minLength={3}
                                required={true}
                                value={shopName} 
                                style={{"fontSize": "20px","textAlign": "center"}}
                                onChange={e => setShopName(e.target.value)}
                            />  

                        </div>
                        <button 
                            // disabled={!(newwaittime)}
                            type="submit">Submit</button>
                    </form>

                )} */}
            </div>
        </div>

    );
}

export default Settings;
