import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation  } from 'react-router-dom';
import { GetUser, clearCache } from '../../App';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { API_URL } from '../../EnvironmentVariables';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoadingSpinner from '../customers/LoadingSpinner';
import useScrollToTop from './useScrollToTop ';
import { CSSTransition, TransitionGroup } from 'react-transition-group'; // Import CSSTransition

const HomePageHeader = () => {
    const [user, setUser] = useState(null); // Assuming you want to store the user
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    const history = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true); // Track loading state

    useEffect( () => {
        const fetchUser = async () => {
            const fetchedUser = await GetUser();

            if (fetchedUser){
                setUser(fetchedUser);
                // history("/dashboard");
            }
            setIsLoading(false);
        };
        fetchUser();

    }, []);

    const toggleBurger = () => {
        setIsBurgerOpen(!isBurgerOpen);
    };
    const ITEM_HEIGHT = 68;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const goToPage = (page_url) => {
        history(page_url);
      };

      const Logout = () => {
        const url = `${API_URL}/api/logout/`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => {
            if(res.ok){
                localStorage.removeItem("token");
                clearCache();
                window.location.reload();
            }
        })
        .catch(errors => console.log(errors));

    };

    useScrollToTop();

    return (
        <div>
            {isLoading ? <LoadingSpinner /> : (
                <div className='flex justify-content-between align-items-center py-4 pl-12 pr-[34px] max-[850px]:pr-[28px] w-full max-w-screen-xl mx-auto my-0'>
                    <div className='flex align-items-center'>
                        <Link to='/'>
                            <img className='h-12' src="/new-logo.png" alt="Logo" />
                        </Link>
                    </div>
                    <div className='max-md:hidden w-4/5 flex justify-content-end align-items-center gap-9'>
                        <div className='w-7/10 flex justify-content-end align-items-center gap-9 max-[850px]:gap-4'>
                            <Link to='/'>
                                <p className={`text-base font-main font-medium hover:text-placeinq ${location.pathname === '/' ? 'border-b-2 border-placeinq' : ''}`}>Home</p>
                            </Link>
                            <Link to='/contact-us'>
                                <p className={`text-base font-main font-medium hover:text-placeinq ${location.pathname === '/contact-us' ? 'border-b-2 border-placeinq' : ''}`}>Contact us</p>
                            </Link>
                            <Link to='/about-us'>
                                <p className={`text-base font-main font-medium hover:text-placeinq ${location.pathname === '/about-us' ? 'border-b-2 border-placeinq' : ''}`}>About us</p>
                            </Link>
                            <Link to='/pricing'>
                                <p className={`text-base font-main font-medium hover:text-placeinq ${location.pathname === '/pricing' ? 'border-b-2 border-placeinq' : ''}`}>Pricing</p>
                            </Link>
                        </div>
                            {!user && (
                                <div className='w-3/10 w-58 flex justify-content-end gap-3'>
                                
                                    <Link to='/signup'>
                                        <button className='placeinq-button'>Sign Up</button>
                                    </Link>
                                    <Link to='/login'>
                                        <p className='w-[100px] py-2 px-3.5 text-black text-base font-main font-medium border-[1.5px] border-solid border-transparent rounded-xl hover:border-slate-300'>Login</p>
                                    </Link>
                                </div>
                            )}
                            {user && (
                                <div className='w-20 flex justify-content-end'>
                                    <Link to='/dashboard'>
                                        <p className="header-account-icon" ><AccountCircleIcon/></p>
                                    </Link>
                                </div>
                            )}
                    </div>
                    
                    <div className="md:hidden flex" onClick={toggleBurger}>
                    {/* <div className='w-3/10 w-58 flex justify-content-end gap-3'> */}
                        <Link to='/login'>
                            <p className='w-[100px] py-2 px-3.5 mr-4 text-black text-base font-main font-medium border-[1.5px] border-solid border-slate-300 rounded-xl'>Login</p>
                        </Link>
                    {/* </div> */}
                    <div>
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                        <MenuIcon />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                            'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                                textAlign: 'center'
                            },
                            }}
                        >

                            {user && (
                                // <>
                                <MenuItem key={"dashboard"} selected={false} onClick={() => {goToPage("/dashboard");handleClose();}}>
                                    <p className='font-main my-0 mx-auto'>Profile</p>
                                </MenuItem>
                                // </>
                            )}
                            <MenuItem key={"Home"} selected={false} onClick={() => {goToPage("/");handleClose();}}>
                                <p className='font-main my-0 mx-auto'>Home</p>
                            </MenuItem>
                            <MenuItem key={"contact us"}selected={false} onClick={() => {goToPage("/contact-us");handleClose();}}>
                                <p className='font-main my-0 mx-auto'>Contact us</p>
                            </MenuItem>

                            <MenuItem key={"about us"} selected={false} onClick={() => {goToPage("/about-us");handleClose();}}>
                                <p className='font-main my-0 mx-auto'>About us</p>
                            </MenuItem>

                            <MenuItem key={"pricing"}selected={false} onClick={() => {goToPage("/pricing");handleClose();}}>
                                <p className='font-main my-0 mx-auto'>Pricing</p>   
                            </MenuItem>


                            {user && (
                                // <>
                                <MenuItem key={"logout"} selected={false} onClick={() => {Logout();handleClose();}}>
                                    <p className='font-main my-0 mx-auto'>Logout</p>
                                </MenuItem>
                                // </>
                            )}

                            {!user && ( 
                                    <MenuItem key={"login"} selected={false} onClick={() => {goToPage("/login"); handleClose();}}>
                                        <p className='w-[100px] py-2 px-3.5 my-0 mx-auto text-black text-base font-main font-medium border-[1.5px] border-solid border-transparent rounded-xl hover:border-slate-300'>Login</p>
                                    </MenuItem>
                                )}

                            {!user && (
                                <MenuItem key={"signup"} selected={false} onClick={() => {goToPage("/signup"); handleClose();}}>
                                    <button className='w-[100px] py-2 px-3.5 my-o mx-auto border-[1.5px] text-black border-solid border-slate-400 rounded-xl shadow-signup text-base font-main font-medium 
                                        transition-transform hover:scale-[0.98] hover:shadow-signupInset border-placeinq'>Sign Up</button>

                                </MenuItem>
                            )}


                        </Menu>
                    </div>

                </div>
                </div>

        )}

        </div>

    );
}

export default HomePageHeader;
