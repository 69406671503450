import React, { useState } from 'react';
import './ContactUsPage.css';
import HomeFooter from '../Home/HomeFooter';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from '../../EnvironmentVariables';

import Swal from 'sweetalert2';
import HomePageHeader from '../Home/HomePageHeader';
import MailOutlineIcon from '@mui/icons-material/MailOutline';


const ContactUsPage = () => {
    const [email, setEmail] = useState('');  // State for email input
    const [message, setMessage] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };


    const handleSubmit = async (event) => {
      event.preventDefault();
      const url = `${API_URL}/api/sendcontactusemail/`;

      try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              'sender_email' : email,
              'message': message
            })
        });

        if (!response.ok) {
            const data = await response.json();
            Swal.fire({
                icon: 'error',
                title: 'Failed to Send Message. Please contact hi@placeinq.com instead',
                text: 'Please contact hi@placeinq.com instead',
              });
            throw new Error('Network response was not ok');
        }
        
        Swal.fire({
          icon: 'success',
          title: 'Your message was successfully sent to PlaceinQ. We will contact you shortly.',
        }); 

        setEmail("");
        setMessage("");



      } catch (error) {
          console.error('Fetch error:', error);
      }
    };
    

    return (
    <div className="flex flex-col">
        <HomePageHeader/>
        <form className="flex flex-col gap-4 px-12 py-12 w-4/5 md:w-[70%] mx-auto my-4 bg-slate-100 rounded-3xl " onSubmit={handleSubmit}>
            <div className='mx-auto mailicon'>
              <MailOutlineIcon/>
              <p className='uppercase font-bold text-3xl mb-4'>Contact us</p>
            </div>
            <label className='flex flex-col'>
              <span className='text-left font-main font-bold'>Your Email:</span>
              <input className='px-2 py-2 rounded-lg border-2 border-slate-300' type="email" value={email} onChange={handleEmailChange} required />
            </label>
            <label className='flex flex-col'>
              <span className='text-left font-main font-bold'>Message:</span>
              <textarea className='px-2 py-2 rounded-lg border-2 border-slate-300 h-52' value={message} onChange={handleMessageChange} required />
            </label>
            {/* <button type="submit">Send</button> */}
            <button type="submit" className='w-full md:w-[150px] py-2 px-3.5 border-[1.5px] text-white bg-placeinq border-solid border-slate-400 rounded-xl shadow-signup text-base font-main font-medium 
                    transition-transform hover:scale-[0.98] hover:shadow-signupInset border-placeinq'>Send</button>

        </form>

        <HomeFooter/>
    </div>
    );
};

export default ContactUsPage;
