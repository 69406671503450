import { API_URL } from '../../EnvironmentVariables';
import './CustomerCard.css';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SmsIcon from '@mui/icons-material/Sms';
import CancelIcon from '@mui/icons-material/Cancel';

function DeleteConfirmationModal({ isOpen, onClose, onConfirm, customerId, customerName }) {
    if (!isOpen) return null;
  
    return (
      <div className="deleteallmodal">
          <h1>Are you sure you want to remove customer {customerName}?</h1>
          <button className="modal-ok" onClick={() => onConfirm(customerId)}>Yes, Remove</button>
          <button className="modal-cancel" onClick={onClose}>Cancel</button>
      </div>
    );
  }

function ServiceConfirmationModal({ isOpen, onClose, onConfirm, customerId, customerName }) {
    if (!isOpen) return null;
  
    return (
      <div className="deleteallmodal">
          <h1>Served {customerName} and want to remove them from the waitlist?</h1>
          <button className="modal-ok" onClick={() => onConfirm(customerId)}>Yes</button>
          <button className="modal-cancel" onClick={onClose}>Cancel</button>
      </div>
    );
  }
function CustomerCard({customer, mode, type}) {
    const [time, setTime] = useState(new Date());
    const [created_at, setCreated_at] = useState(); 
    const [updated_at, setUpdated_at] = useState(); 
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isServiceModalOpen, setServiceModalOpen] = useState(false);

    const [waitTime, setWaitTime] = useState(); 

    useEffect(() => {
        const created_at_date = new Date(customer.created_at);
        const updated_at_date = new Date(customer.updated_at);
        setCreated_at(created_at_date.toLocaleString([], { hour: '2-digit', minute: '2-digit' }));
        setUpdated_at(updated_at_date.toLocaleString([], { hour: '2-digit', minute: '2-digit' }));

        const timerId = setInterval(() => {
            setTime(new Date());
            setUpdated_at(updated_at_date.toLocaleString([], { hour: '2-digit', minute: '2-digit' }));
        }, 60000);

        return () => clearInterval(timerId);
    }, []);


    function calculateTimeDifference(startTime, endTime) {
        const start = new Date(`1970-01-01T${startTime}Z`); // Assuming times are in HH:MM format
        const end = new Date(`1970-01-01T${endTime}Z`);
    
        const differenceInMilliseconds = end - start;
    
        const hours = Math.floor(differenceInMilliseconds / 3600000); // 3600000 milliseconds in an hour
        const minutes = Math.floor((differenceInMilliseconds % 3600000) / 60000); // 60000 milliseconds in a minute
        
        const timeDifference = { hours, minutes }
        setWaitTime({ hours, minutes })
        return timeDifference;
    }

    const handleSendSMS = (customerId) => {
        const sendSMS = async () => {
            const url = `${API_URL}/api/sendsms/`;

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        'customer_id': customerId,
                        'mode': mode
                    })
                });

                if (!response.ok) {
                    const data = await response.json();
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed to Send Message',
                      });
                    throw new Error('Network response was not ok');
                }
                
                message();

            } catch (error) {
                console.error('Fetch error:', error);
            }
        };
        sendSMS();
    };

    const handleChangeStatus = (customerId, newStatus) => {
        console.log('Changing status for customer ID:', customerId, 'to', newStatus);
    };

    const handleDeleteCustomer = (customerId) => {

        const deleteCustomer = async () => {
            const url = `${API_URL}/api/deletecustomer/`;

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        'customer_id': customerId,
                    })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                // window.location.reload();

            } catch (error) {
                console.error('Fetch error:', error);
            }
        };
        deleteCustomer();
    };

    const handleServeCustomer = (customerId) => {

        const serveCustomer = async () => {
            const url = `${API_URL}/api/servecustomer/`;

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        'customer_id': customerId,
                    })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                // window.location.reload();

            } catch (error) {
                console.error('Fetch error:', error);
            }
        };
        serveCustomer();
    };


    const message = () =>{
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          Toast.fire({
            icon: "success",
            title: "Invitation Was Successfully Sent to Customer!"
          });
    }


    return (
    <tr key={customer.id} className="customer-row">
        <td data-label="name">{customer.name || 'Guest'}</td>

        <td data-label="#guests">{customer.guests_number}</td>

        <td data-label="waiting time" className='waiting-time'
        style={{ 
            color: type=='completed' ? 'black' :
            customer.waiting_time < 10 ? '' :
            (customer.waiting_time < 20 ? '#ff8100' : 
            (customer.waiting_time < 30 ? '#ff8100' : 
            '#ff8100'))
        }}
        >{customer.waiting_time === 0 ? 'less than 1 min': 
            (customer.waiting_time === 1 ? '1 min':
            customer.waiting_time + ' mins')}</td>


        <td data-label="status" className="customer-status"
        style={{ 
            color: (customer.status === "Cancelled") ? 'orange' : 
                    (customer.status === "Served") ? 'green' : 
                    (customer.status === "Removed") ? 'red' : 
                (customer.invited < 10 || customer.created_at===customer.updated_at) ? '' :
            (customer.invited < 20 ? '#ff8100' : 
            (customer.invited < 30 ? '#ff8100' : 
            '#ff8100'))
        }}    
        >{  customer.status === "Cancelled" ? 'cancelled' :
            customer.status === "Served" ? 'served' :
            customer.status === "Removed" ? 'removed' :
            customer.created_at===customer.updated_at ? 'waiting': 
            (customer.invited === 0 ? 'invited less than 1 min ago':
            (customer.invited === 1 ? 'invited 1 min ago':
            'invited ' + customer.invited + ' mins ago'))}</td>

        <td data-label="phone number">
            {/* ******{customer.phone_number.slice(-4)} */}
            <span className="masked-phone">******{customer.phone_number.slice(-4)}</span>

            </td>


        {type=='waiting'&&
        <td data-label="actions" className="action-buttons"> 
            <CheckCircleIcon className="service-customer-button-icon" style={{ fontSize: '2.5rem' }} onClick={() => setServiceModalOpen(true)}/>
            {/* <SmsIcon/> */}
            <button className="sms-button"
            disabled={customer.status === "Cancelled" ? true: false}
            style={{ backgroundColor: customer.status === 'waiting' ? '' : '#000000' }}
            onClick={() => handleSendSMS(customer.id)}>
                {customer.status === 'waiting'? 'Invite' : 
                customer.status === 'Cancelled'? '-' :
                'Re-invite'}</button>
            
            <CancelIcon className="remove-customer-button-icon" style={{ fontSize: '2.5rem' }} onClick={() => setDeleteModalOpen(true)}/>
            {/* <button className="delete-button" onClick={() => setModalOpen(true)}>Remove</button> */}
        </td>
        }
        <DeleteConfirmationModal 
            isOpen={isDeleteModalOpen} 
            onClose={() => setDeleteModalOpen(false)} 
            onConfirm={handleDeleteCustomer} 
            customerId={customer.id}
            customerName={customer.name}
        />
        
        <ServiceConfirmationModal 
            isOpen={isServiceModalOpen} 
            onClose={() => setServiceModalOpen(false)} 
            onConfirm={handleServeCustomer} 
            customerId={customer.id}
            customerName={customer.name}
        />

    </tr>
    

);
}

export default CustomerCard;
