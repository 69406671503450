
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import React, { useState, useEffect, useRef } from 'react';
import './bd-wizard.css'; 
import './CustomersPage.css';
import { useParams, useNavigate } from 'react-router-dom';
import { API_URL } from '../../EnvironmentVariables';
import Swal from 'sweetalert2'
import CustomerFooter from "./CustomerFooter";
import LoadingSpinner from './LoadingSpinner'; // Adjust the path as necessary
import { PatternFormat } from 'react-number-format';
import { GetUser } from "../../App";
import { People, Person } from "@mui/icons-material";
import PortraitIcon from '@mui/icons-material/Portrait';
import GroupsIcon from '@mui/icons-material/Groups';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MaskedInput from 'react-text-mask';
import FormattedInput from "./FormattedInput";

const fetchRestaurant = async (restaurant_name) => {
  try {
    const url = `${API_URL}/api/get-restaurant-by-name/`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'restaurant_name': restaurant_name,
      })
    });
    if (!response.ok) throw new Error('Restaurant fetch failed');
    return await response.json(); // Assuming the API returns JSON data
  } catch (error) {
    console.error(error);
    return null;
  }
}


export default function CustomersPage() {
  let { restaurantName } = useParams();
  const history = useNavigate();
  const inputRefStep2 = useRef(null);
  const inputRefStep3 = useRef(null);

  const [customerName, setCustomerName] = useState('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [numberOfGuests, setNumberOfGuests] = useState(1);
  const [step, setStep] = useState(1);
  const [isBarbershop, setIsBarbershop] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [restaurant_logo, setRestaurant_logo] = useState('https://placeinq-bucket.s3.amazonaws.com/'); 
  const [restaurant_wait_time, setRestaurant_wait_time] = useState('10'); 
  const [restaurantIsLoggedIn, SetRestaurantIsLoggedIn] = useState(false); 

  useEffect(() => {
      const getRestaurantData = async () => {
        if(restaurantName === "xerxes-barber-lounge"){
          history('/xerxes');
          return;
        } 
        const restaurantData = await fetchRestaurant(restaurantName);
        if (!restaurantData) {
          history('/not-found');
          return;
        } else {
          if(restaurantName === "xerxes"){
            setIsBarbershop(true);
            setStep(2);
          }
          setRestaurant_logo(restaurantData.restaurant_logo);
          setRestaurant_wait_time(restaurantData.restaurant_wait_time);
          const fetchedUser = await GetUser();
          if (fetchedUser !== null && fetchedUser.restaurant_name === restaurantName) 
            SetRestaurantIsLoggedIn(true)

          setIsLoading(false);
        }
      };
  
      getRestaurantData();
  }, [restaurantName, history]);

  useEffect(() => {
    if (step === 2 && inputRefStep2.current) {
      inputRefStep2.current.focus();
    }
    if (step === 3 && inputRefStep3.current) {
      inputRefStep3.current.focus();
    }
}, [step, inputRefStep2.current]); 


const handleKeyPress = (event) => {
  if (event.key === "Enter") {
    nextStep(); 
  }
};

useEffect(() => {
  if(step > 1){
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }

}, [step, customerPhoneNumber, customerName]);

const handlePhoneNumberChange = (phoneNumber) => {
  // Here you could also strip formatting before setting the state if needed
  setCustomerPhoneNumber(phoneNumber);
};
  const increment = () => {
    setNumberOfGuests((prevNumber) => prevNumber + 1);
  };

  const nextStep = () => {
    if(step===3){
      handleComplete();
    }
    else if(step ===2){
      const digits_only = customerPhoneNumber.replace(/\D/g, '');
      const ten_digit_number = digits_only.slice(-10);

      if(ten_digit_number.length !== 10){
        Swal.fire({
          icon: 'error',
          title: 'Invalid phone number',
        });
      }
      else{
        setStep((prevStep) => prevStep < 3 ? prevStep + 1 : 3);
      }
    }
    else{
        setStep((prevStep) => prevStep < 3 ? prevStep + 1 : 3);
    }
  };
  const prevStep = () => {
    setStep((prevStep) => prevStep > 1 ? prevStep - 1 : 1);
  };

  const decrement = () => {
    setNumberOfGuests((prevNumber) => prevNumber > 1 ? prevNumber - 1 : 1);
  };

  const handleChange = (e) => {
    let value = parseInt(e.target.value, 10);
    value = isNaN(value) ? 1 : value < 1 ? 1 : value;
    setNumberOfGuests(value);
  };

  const handleComplete = () => {
    const addCustomer = async () => {
        const url = `${API_URL}/api/addcustomer/`;
        const digitsOnly = customerPhoneNumber.replace(/\D/g, '');
        const tenDigitNumber = digitsOnly.slice(-10);

        try{
          if(tenDigitNumber.length !== 10){
            throw new Error('');
          }
        }
        catch (error) {
          console.error('Fetch error:', error);
          Swal.fire({
              icon: 'error',
              title: 'Invalid phone number',
            });
        }
        try {
          if(tenDigitNumber.length !== 10){
            throw new Error('InvalidPhoneNumber');
          }
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'name': customerName === ''?'there':customerName,
                    'phone_number': tenDigitNumber,
                    'guests_number': numberOfGuests,
                    'restaurant_name': restaurantName,
                })
            });

            if (!response.ok) {
                throw new Error('NetworkError');
            }

            setShowModal(true);
            setTimeout(() => {
                setShowModal(false);
                window.location.reload();
              }, 5000);

        } catch (error) {
            if (error.message === 'InvalidPhoneNumber') {
              Swal.fire({
                  icon: 'error',
                  title: 'Invalid Phone Number',
              });
            }else{

              Swal.fire({
                  icon: 'error',
                  title: 'Adding to the waitlist failed',
                  text: 'There was an error adding you to the queue.',
                });
          }
      }
    };
    addCustomer();
};

const handleOk = () => {
    setShowModal(false);
    window.location.reload();
};
const goToDashboard = () => {
  history("/dashboard")
};
  const checkValidateTab = () => {
    if (customerPhoneNumber.includes("_")) {
      return false;
    }
    return true;
    if (customerPhoneNumber === "" || customerPhoneNumber.length !== 10) {
      return false;
    }
    return true;

  };
  const errorMessages = () => {
    alert("Please provide a correct phone number");
  };

  function guestsIcon() {
    return (
      <img
        src="/guests-number.png"
        alt="vitejs"
        width="34"
        height="34"
      />
    );
  }
  function phoneIcon() {
    return (
      <img
        src="/phone-call.svg"
        alt="vitejs"
        width="24"
        height="24"
      />
    );
  }
  function nameIcon() {
    return (
      <img
        src="/name-svgrepo-com.svg"
        alt="vitejs"
        width="28"
        height="28"
      />
    );
  }


  return (
    
    <div>
        {isLoading ? <LoadingSpinner /> : (
            
        <div className="customerspage">


            {!showModal&&
            <>
              {restaurantIsLoggedIn &&
                  <div className="go-to-dashboard-btn">
                      {/* <div>&lt;</div> */}
                      <button onClick={goToDashboard}>Back to dashboard</button>
                  </div>
                }
                <div className="customerspage-header">
                      
                    <div className="customerspage-header-businessName">
                    {/* {restaurantName
                      .split('-') 
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
                      .join(' ')}                     */}

                      Join our waitlist
                    </div>
                    <div className="customerspage-header-businessLogo">
                        <img src={restaurant_logo==='https://placeinq-bucket.s3.amazonaws.com/'?"/Q-logo.png": restaurant_logo} alt="Business Logo" />
                    </div>
                </div>
                
                <div className="customerspage-getinfo">
                    <div className="customerspage-getinfo-input">
                        <label>{step === 1 ? 'Party Size' : step === 2 ? 'Phone number' : step === 3 ? 'Name' : ''}</label>
                        <div className="customerspage-getinfo-input-partysize" style={{ display: step === 1 ? "flex" : "none" }}>
                            <div className="customerspage-getinfo-input-incdecbutton dec" onClick={decrement}>-</div>
                            <div className="customerspage-getinfo-input-partysize-input">{numberOfGuests}</div>
                            <div className="customerspage-getinfo-input-incdecbutton" onClick={increment}>+</div>
                        </div>

                        <div className="customerspage-getinfo-input-normal" style={{ display: step === 2 ? "flex" : "none" }}>
                            <FormattedInput
                              value={customerPhoneNumber}
                              onChange={handlePhoneNumberChange}
                              step={step}
                              inputRef={inputRefStep2}
                            />
                        </div>
                        
                        <div className="customerspage-getinfo-input-normal" style={{ display: step === 3 ? "flex" : "none" }}>
                        <input
                            ref={inputRefStep3}
                            type="text"
                            value={customerName}
                            className="customerspage-getinfo-input-normal-input"
                            onChange={(e) => setCustomerName(e.target.value)}/> 

                        </div>
                    </div>
                    <div className="customerspage-getinfo-buttons">
                        <button className={`customerspage-getinfo-buttons-next ${step===3?'final_step':''}`} onClick={nextStep}>
                            {step===3?'Join Waitlist':'Next'}
                        </button>
                        <div className={`customerspage-getinfo-buttons-back ${((step>1 && !isBarbershop) || (step>2 && isBarbershop))?'active':''}`} onClick={prevStep}>
                            Back
                        </div>
                    </div>
                    <div className={`customerspage-getinfo-steps ${isBarbershop?'hide':''}`}>
                        <div className={`customerspage-getinfo-step active`} onClick={() => setStep(1)}>
                            <GroupsIcon/>
                        </div>
                        <div className={`customerspage-getinfo-step ${step>=2?'active':''}`} onClick={() => setStep(2)}>
                            <LocalPhoneIcon/>
                        </div>
                        <div className={`customerspage-getinfo-step ${step===3?'active':''}`} onClick={() => setStep(3)}>
                            <Person/>
                        </div>
                    </div>
                </div>

                <div className="customerspage-footer">
                    @placeinq.com
                </div>
            </>
            }
            {showModal && (
                    <div className="confirmationModal">
                        <span className="check-mark">✔️</span>
                        <h1>Thank you!</h1>
                        <p>We will message you when your spot is ready</p>                
                        {/* <p>Estimated wait time: {restaurant_wait_time}</p> */}
                        <button className="modal-ok" onClick={handleOk}>OK</button>
                    </div>
            )}
            {/* <CustomerFooter /> */}

        </div>
        )}
    </div>
  );
}
