// Blog.js
import React from 'react';
import './Blog.css';
import { Link } from 'react-router-dom';

const BlogBox = ({ title, summary, link, imgsrc }) => {
  return (
    <div className="blogbox">
      <h3>{title}</h3>
      <div className='blogbox-image'>
          <img src={imgsrc} alt="blog image" />                        

      </div>
        <p>{summary}</p>

      <Link to={link}>Read More</Link>
    </div>
  );
};

export default BlogBox;
