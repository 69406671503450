import { API_URL } from '../../EnvironmentVariables';
import './RestaurantDashboard.css'; // Your CSS file for styling
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation  } from 'react-router-dom';
import { GetUser, clearCache } from '../../App';
import LoadingSpinner from '../customers/LoadingSpinner';
import Settings from './Settings';


import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import "shepherd.js/dist/css/shepherd.css";
import Shepherd from 'shepherd.js';
// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';
// import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import { jsPDF } from 'jspdf';
import DashboardWaitlist from './DashboardWaitlist';

const ITEM_HEIGHT = 48;

function RestaurantDashboard() {
    let [customers, setCustomers] = useState([]);
    let [completed_customers, setCompleted_customers] = useState([]);
    const [user, setUser] = useState(null); // Assuming you want to store the user

    const [activeTab, setActiveTab] = useState('waitlist'); // 'plan' or 'customers'

    const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
    const [showQRCodeModal, setShowQRCodeModal] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Track loading state
    const [isBarbershop, setIsBarbershop] = useState(false);

    const subscriptionPlans = [
        { id: 1, title: "Free", texts: 300, price: 0 },
        { id: 2, title: "Standard", texts: 1000, price: 19.99 },
        { id: 3, title: "Premium", texts: 3000, price: 49.99 }
    ];
    
    const history = useNavigate();
    // const stripe = useStripe();


    const buttonConfig = [
        {
            action() {
                return this.back();
            },
            classes: 'shepherd-button-secondary',
            text: 'Back'
        },
        {
            action() {
                return this.next();
            },
            text: 'Next'
        }
      ];

      const tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
          classes: 'shadow-md',
          cancelIcon: {
            enabled: true
            },
          scrollTo: true
        }
      });


    //   tour.addStep({
    //     id: '-1',
    //     text: 'Hi! Let\'s take a quick tour on how to use your placeinQ dashboard.',
    //     attachTo: {
    //       element: '.main-content',
    //       on: 'bottom'
    //     },
    //     classes: 'example-step-extra-class',
    //     buttons: [
    //       {
    //         text: 'Next',
    //         action: tour.next
    //       }
    //     ]
    //   });

    //   tour.addStep({
    //     id: '0',
    //     text: 'Click to open the customers page to add guests to the waitlist',
    //     attachTo: {
    //       element: '.to-customers-page',
    //       on: 'bottom'
    //     },
    //     classes: 'example-step-extra-class',
    //     buttons: [
    //       {
    //         text: 'Next',
    //         action: tour.next
    //       }
    //     ]

    // });

      tour.addStep({
        id: '1',
        text: 'This is your waitlist',
        attachTo: {
          element: '.dashboard-waitlist-container',
          on: 'top-start'
        },
        classes: 'example-step-extra-class',
        buttons: buttonConfig

      });

      tour.addStep({
        id: '2',
        text: 'This is the latest customer added to the waitlist',
        attachTo: {
          element: '.dashboardWaitlistCustomer',
          on: 'bottom'
        },
        classes: 'example-step-extra-class',
        buttons: buttonConfig
      });

      tour.addStep({
        id: '3',
        text: 'You can monitor their waiting time',
        attachTo: {
          element: '.dashboardWaitlistCustomer',
          on: 'bottom'
        },
        classes: 'example-step-extra-class',
        buttons: buttonConfig
      });

      tour.addStep({
        id: '4',
        text: 'You can also monitor their status: waiting, invited, ...',
        attachTo: {
          element: '.dashboardWaitlistCustomer',
          on: 'bottom'
        },
        classes: 'example-step-extra-class',
        buttons: buttonConfig
      });

      tour.addStep({
        id: '5',
        text: 'Now let\'s try sending an invitation sms to the customer by clicking on the "Invite" button.',
        attachTo: {
          element: '.dashboardWaitlistCustomer-cta-invite',
          on: 'bottom'
        },
        classes: 'example-step-extra-class',
        buttons: buttonConfig
      });

      tour.addStep({
        id: '6',
        text: 'When you serve your customer, click on the check mark to emove them from the waitlist',
        attachTo: {
          element: '.dashboardWaitlistCustomer-cta-service',
          on: 'bottom'
        },
        classes: 'example-step-extra-class',
        buttons: buttonConfig
      });

      tour.addStep({
        id: '7',
        text: 'You can always remove a customer from the waitlist by cicking on the "X" button.',
        attachTo: {
          element: '.dashboardWaitlistCustomer-cta-remove',
          on: 'bottom'
        },
        classes: 'example-step-extra-class',
        // buttons: buttonConfig
        buttons: [
            {
                action() {
                    return this.back();
                },
                classes: 'shepherd-button-secondary',
                text: 'Back'
            },
            {
                action() {
                    return this.complete();
                },
                text: 'Finish'
            }
          ]

      });


    //   tour.addStep({
    //     id: '7',
    //     text: 'Great! now let\'s activate your account by clicking on this button.',
    //     attachTo: {
    //       element: '.activate-account-btn',
    //       on: 'left'
    //     },
    //     classes: 'example-step-extra-class',
    //     buttons: [
    //         {
    //             action() {
    //                 return this.back();
    //             },
    //             classes: 'shepherd-button-secondary',
    //             text: 'Back'
    //         },
    //         {
    //             action() {
    //                 return OpenActivateAccountModal();
    //             },
    //             text: 'Activate account'
    //         }
    //       ]
    //   });


    const [tourIsActive, SetTourIsActive] = useState(false);

    useEffect( () => {
        let ws; 
        let uid;
        const fetchUser = async () => {
            const fetchedUser = await GetUser();

            if (fetchedUser === null) 
                history('/login');
            else{
                uid = fetchedUser.id;
                if(fetchedUser.restaurant_name === "xerxes"){
                    setIsBarbershop(true);
                }
                get_customers(uid);
                get_completed_customers(uid);

                if(fetchedUser.first_login){
                    const timer = setTimeout(() => {
                        if (tour) {
                            tour.start();
                        }
                    }, 3000);
                }
                changeFirstLoginStatus();
                // ws = new WebSocket(`ws://127.0.0.1:8000/ws/restaurant/${uid}/`);
                // ws.onmessage = (event) => {
                //     const data = JSON.parse(event.data);
                //     setMessage(data.message);
                //     get_customers(uid);
                // };
                // ws.onopen = () => {
                //     console.log("WebSocket connected");
                // };
                // ws.onerror = (error) => {
                //     console.error("WebSocket error: ", error);
                // };
                // ws.onerror = function(event) {
                //     console.error("WebSocket error observed:", event);
                //   };

                setUser(fetchedUser);
                setIsLoading(false);
            }        
        };
        fetchUser();
        const intervalId = setInterval(() => get_customers(uid), 1000); // 5000 ms = 5 seconds
        const intervalId2 = setInterval(() => get_completed_customers(uid), 5000); // 5000 ms = 5 seconds
        return () => clearInterval(intervalId);        
        // return () => {
        //     if (ws) {
        //         ws.close(); // Close the WebSocket connection when the component unmounts
        //     }
        // };

    }, [history]);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         if (tour) {
    //             tour.start();
    //         }
    //     }, 3000);
    //   }, []); 

    const qrCodeRef = useRef(null);
    const downloadQRCode = () => {

        htmlToImage.toPng(qrCodeRef.current)
          .then(function (dataUrl) {
            const pdf = new jsPDF();

            const logoX = 15; // X coordinate for the logo
            const logoY = 10; // Y coordinate for the logo
            const logoWidth = 40; // Width of the logo
            const logoHeight = 40; // Height of the logo
      
            const qrCodeX = 15; // X coordinate for the QR code
            const qrCodeY = logoY + logoHeight + 10; // Y coordinate for the QR code, placed below the logo
            const qrCodeWidth = 180; // Width of the QR code
            const qrCodeHeight = 160; // Height of the QR code
      
            // Load the logo image (ensure CORS policy allows this)
            const logoSrc = user.restaurant_logo ? user.restaurant_logo : "https://logowik.com/content/uploads/images/restaurant9491.logowik.com.webp";
            // pdf.addImage(logoSrc, 'PNG', logoX, logoY, logoWidth, logoHeight);
      
            // Add the QR code image next
            pdf.addImage(dataUrl, 'PNG', qrCodeX, qrCodeY, qrCodeWidth, qrCodeHeight);
      

            // pdf.addImage(dataUrl, 'PNG', 15, 40, 180, 160);

            pdf.save(`${user.restaurant_name}_QRcode.pdf`);
    
          })
          .catch(function (error) {
            console.error("Error generating QR code:", error);
          });

      };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const Logout = () => {
        const url = `${API_URL}/api/logout/`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => {
            if(res.ok){
                localStorage.removeItem("token");
                clearCache();
                history('/login');
            }
        })
        .catch(errors => console.log(errors));

    };

    const changeFirstLoginStatus = async (event) => {
        const url = `${API_URL}/api/me/`;
        return fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                'first_login': false
            })
        })
        .then(resp => {
            if (!resp.ok) {
                console.log("Unable to change the restaurant first login status")
            }
        })
    }



    const CloseDeleteAllCustomersModal = () => {
        setShowDeleteAllModal(false);
    }

    const OpenQRCodeModal = () => {
        setShowQRCodeModal(true);
    }
    const CloseQRCodeModal = () => {
        setShowQRCodeModal(false);
    }
    
    async function get_customers(uid) {
        const loadCustomers = async (uid) => {
            const url = `${API_URL}/api/get-customers/`;
            try {
                const response = await fetch(url, {
                    credentials: 'include',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        'restaurant_id': uid,
                    })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setCustomers(data);

            } catch (error) {
                console.error('Fetch error:', error);
            }
        };

            loadCustomers(uid);
    }   


    async function get_completed_customers(uid) {
        const loadCompletedCustomers = async (uid) => {
            const url = `${API_URL}/api/get-completed-customers/`;
            try {
                const response = await fetch(url, {
                    credentials: 'include',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        'restaurant_id': uid,
                    })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setCompleted_customers(data);

            } catch (error) {
                console.error('Fetch error:', error);
            }
        };

        loadCompletedCustomers(uid);
    }   



    const deleteallcustomers = async() => {
        const url = `${API_URL}/api/deleteallcustomers/`;
        
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    'restaurant_id': user.id,
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // window.location.reload();

        } catch (error) {
            console.error('Fetch error:', error);
        }
        CloseDeleteAllCustomersModal();
    };


    const handlePurchase = (planId) => {
        const public_key_url = `${API_URL}/api/get-stripe-public-key/`;
        const secret_key_url = `${API_URL}/api/create-checkout-session/`;

        fetch(public_key_url)
        .then((result) => { return result.json(); })
        .then((data) => {
        console.log("Purchased plan:", data.publicKey);
        // const stripePromise = loadStripe(data.publicKey);

        fetch(secret_key_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        })
        // .then((result) => { return result.json(); })
        // .then((data) => {
        //   console.log(data);

        //   // Redirect to Stripe Checkout
        //   const options = {
        //     // passing the client secret obtained from the server
        //     clientSecret: `{{$data.sessionId}}`,
        //   };

        // //   window.open(`checkout.stripe.com/pay/${data.sessionId}`);
        // //   return stripe.redirectToCheckout({sessionId: data.sessionId})
        // })
        // .then((res) => {
        //   console.log(res);
        // });
    //   });

        })
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <div>
            {isLoading ? <LoadingSpinner /> : (
            
            <div className="dashboard-container">

                <div className='n-sidebar'>
                    <div className='n-sidebar-logo'>
                        <Link to='/'>
                            <img src="/new-logo.png" alt="Logo" />
                        </Link>
                    </div>

                    <div className='n-sidebar-tabs'>
                        <div className={`n-sidebar-tab ${activeTab === 'waitlist' ? 'active' : ''}`} onClick={() => setActiveTab('waitlist')}>
                            <div className="n-sidebar-tab-icon">
                                <PeopleAltIcon/>
                            </div> 
                            <div className="n-sidebar-tab-title">
                                Waitlist
                            </div>
                        </div>
                        <div className={`n-sidebar-tab ${activeTab === 'settings' ? 'active' : ''}`} onClick={() => setActiveTab('settings')}>
                            <div className="n-sidebar-tab-icon">
                                <SettingsIcon/>
                            </div> 
                            <div className="n-sidebar-tab-title">
                                Settings
                            </div>
                        </div>
                        <div className="n-sidebar-tab" onClick={OpenQRCodeModal}>
                        {/* <div className={`n-sidebar-tab ${activeTab === 'qrcode' ? 'active' : ''}`} onClick={() => setActiveTab('qrcode')}> */}
                            <div className="n-sidebar-tab-icon">
                                <QrCode2Icon/>
                            </div> 
                            <div className="n-sidebar-tab-title">
                                QR Code
                            </div>
                        </div>

                    </div>

                    <div className='n-sidebar-logout'>
                        <div className="n-sidebar-tab"  onClick={Logout}>
                            <div className="n-sidebar-tab-icon">
                                <PowerSettingsNewIcon/>
                            </div> 
                            <div className="n-sidebar-tab-title">
                                Log Out
                            </div>
                        </div>
                    </div>
                </div>

                <div className="phone-header-dashboard" onClick={toggleSidebar}>

                    <div className="hamburger-icon">
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                            'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 5.5,
                                width: '20ch',
                            },
                            }}
                        >

                            <MenuItem key={"Wait List"} selected={false} onClick={() => {setActiveTab('waitlist'); handleClose();}}>
                                Wait List
                            </MenuItem>

                            <MenuItem key={"Settings"} selected={false} onClick={() => {setActiveTab('settings'); handleClose();} }>
                                Settings
                            </MenuItem>

                            <MenuItem key={"QRCode"} selected={false} onClick={() => {OpenQRCodeModal(); handleClose();} }>
                                QR Code
                            </MenuItem>

                            <MenuItem key={"Logout"} selected={false} onClick={Logout}>
                                Log Out
                            </MenuItem>

                        </Menu>
                    </div>


                        <div className="phone-header-logo">
                            <Link to='/'>
                            <img src="/new-logo.png" alt="Logo" />
                            </Link>
                        </div>

                    <div className="placeholder" >

                    </div> 

                </div>


                <div className="main-content">

                    {(activeTab === 'waitlist') && (
                        <DashboardWaitlist 
                        customers={customers}
                        completed_customers={completed_customers}
                        restaurant_name={user.restaurant_name}
                        isBarbershop={isBarbershop}/>
                    )}

                    {activeTab === 'plan' && (

                        <div className="subscription-plans">
                            <h1>PlaceinQ Plans</h1>
                            <div className="plans-container">
                                {subscriptionPlans.map((plan) => (
                                    <form key={plan.id} className="plan" action={`${API_URL}/api/create-checkout-session/${plan.id}/`} method="POST">
                                        <p><strong>{plan.title}</strong> </p>
                                        <p><strong>Notifications:</strong> {plan.texts}</p>
                                        <p><strong>Price:</strong> ${plan.price}</p>
                                        {/* <button onClick={() => handlePurchase(plan.id)}>Purchase</button> */}
                                        <button type="submit">Purchase</button>

                                    </form>
                                ))}
                            </div>
                        </div>
                    )}

                    {(activeTab === 'settings') && (
                        <Settings
                        user={user}
                        />
                    )}

                    {showDeleteAllModal && (
                        <div className="deleteallmodal">
                            <h1>Are you sure you want to remove all the customers?</h1>
                            <button className="modal-ok" onClick={deleteallcustomers}>Yes</button>
                            <button className="modal-cancel" onClick={CloseDeleteAllCustomersModal}>Cancel</button>
                        </div>
                    )}

                    {showQRCodeModal && (

                        <div className="confirmationModal" style={{"width": "400px"}}>
                                <div className="QRCode" ref={qrCodeRef} >
                                
                                <p style={{"fontSize": "15px"}}>Please scan the QR code below to join our waitlist</p>

                                <div className='QRCode-container'>
                                    <QRCode 
                                    size={150}
                                    bgColor='white'
                                    fgColor='#3c096c'
                                    value={`placeinq.com/${user.restaurant_name}`}
                                    />
                                </div>
                                <h5 className='QRCode-website'>placeinq.com/{user.restaurant_name}</h5>
                                
                                <p className='QRCode-footer'>This service is brought to you by placeinq.com</p>
                            </div>
                            <br/>

                            <button className="modal-ok" onClick={downloadQRCode}>Download</button>
                            <button className="modal-cancel" onClick={CloseQRCodeModal}>Cancel</button>
                        </div>
                    )}

                </div>

            </div>
            )}
        </div>

    );
}

export default RestaurantDashboard;
