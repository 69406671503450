import React from 'react';
import HomeFooter from '../Home/HomeFooter';
import HomePageHeader from '../Home/HomePageHeader';

const AboutusPage = () => {
  return (
    <div className="felx flex-col">
        <HomePageHeader/>
        <div className="flex flex-col md:flex-row gap-4 px-12 py-12 items-center">
            <div className="text-justify">
                <h1 className="font-bold mb-2">About us</h1>
                <p>We launched PlaceinQ in January 2024 after witnessing the growing prevalence 
                    of long queues at restaurants in Vancouver. We found waiting in line, 
                    particularly in Vancouver's rainy climate, to be inconvenient, deterring 
                    customers and causing businesses to lose money and opportunities. 
                    Moreover, we identified the strain on businesses to assign dedicated 
                    staff for walk-ins, prompting us to develop PlaceinQ. 
                    With PlaceinQ, customers can add themselves to the virtual waitlist without 
                    the need for staff assistance and receive a text notification when it's their
                    turn, eliminating the need to physically stand in line for extended periods.</p>
                <p>We are committed to providing a solution that not only attracts more customers
                     but also reduces staffing costs while maintaining customer satisfaction. 
                     At PlaceinQ, our mission is to offer an easy-to-use yet practical solution 
                     for businesses to efficiently organize their waitlists and enhance the 
                     customer experience. Although we initially focused on food and restaurant 
                     businesses, we understand the universal need for our service across various 
                     industries and are expanding accordingly.</p>
                <p>Founded by brothers Amir, with expertise in engineering consulting and project 
                    management, and Sina, with a background in software engineering and AI, 
                    we've assembled a dynamic team dedicated to improving our product's quality 
                    every day.</p>
            </div>
            <img src="/homepage-images/5.png" alt="Product" className="max-w-[450px] rounded-2xl md:ml-4"/>

        </div>
        <div className="px-12 pb-12">
            <div className="text-left">
                <h2 className="font-bold mb-2">Product</h2>
                <p>With PlaceinQ, customers can effortlessly join a waitlist by scanning a QR code or using a provided tablet. Once added to the queue, customers receive a notification about their status in queue, eliminating the need to wait in line or guess when they'll be served. This not only reduces wait times but also enhances the overall customer experience.</p>
                <p>For businesses, PlaceinQ offers a powerful and intuitive dashboard, allowing staff to easily manage waitlists, monitor queue flow, and communicate with customers. Our system helps streamline operations, increase efficiency, and reduce operational costs, enabling businesses to focus on what they do best – delivering exceptional service.</p>
            </div>
            {/* <img src="/homepage-images/5.png" alt="Product" className="sectionImage"/> */}
        </div>
        <HomeFooter/>
    </div>
  );
};

export default AboutusPage;
