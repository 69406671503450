import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './HomePage.css'; // Import your CSS stylesheet
import HomeFooter from './HomeFooter';
import { GetUser } from '../../App';
import HomeImageSlider from './HomeImageSlider';
import PaidIcon from '@mui/icons-material/Paid';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import FAQ from './FAQ';
import HomePageHeader from './HomePageHeader';

function HomePage({customer}) {
    const history = useNavigate();
    const [user, setUser] = useState(null); // Assuming you want to store the user

    // useEffect(() => {
        // window.intercomSettings = {
        //   api_base: "https://api-iam.intercom.io",
        //   app_id: "a3aq69sg",
        // };
    
        // Function to load the Intercom widget script
        // const loadIntercomScript = () => {
        //   const script = document.createElement('script');
        //   script.type = 'text/javascript';
        //   script.async = true;
        //   script.src = 'https://widget.intercom.io/widget/a3aq69sg';
        //   document.body.appendChild(script);
    
        //   script.onload = () => {
        //     if (typeof window.Intercom === "function") {
        //       window.Intercom('reattach_activator');
        //       window.Intercom('update', window.intercomSettings);
        //     }
        //   };
        // };
    
        // Attach event listeners to load the script properly
        // if (document.readyState === 'complete') {
        //   loadIntercomScript();
        // } else {
        //   window.addEventListener('load', loadIntercomScript);
        // }
    
        // Clean up function to remove event listeners if the component unmounts
        // return () => {
        //   window.removeEventListener('load', loadIntercomScript);
        // };
    //   }, []);


    const [faqs, setFaqs] = useState([
        {
          question: "What is virtual waitlist management?",
          answer:
          "Virtual waitlist management replaces traditional physical queues with an online system where customers can join a waitlist using their mobile devices and receive updates and notifications via text message.",
          open: false
        },
        {
          question: "How do customers join the waitlist?",
          answer: "Customers can join the waitlist by visiting your provided link or scanning a QR code displayed at your location. They simply enter their details and will be added to the queue automatically.",
          open: false
        },
        {
          question:
            "Can I see how many customers are waiting?",
          answer: "Yes, your dashboard provides a real-time view of all customers currently on the waitlist, their wait times, and their position in the queue.",
          open: false
        },
        {
            question:
              "How do customers know when it's their turn?",
            answer: "Once it's their turn, customers receive a text message inviting them to proceed to the service area.",
            open: false
        },
        {
            question:
              "How secure is the customer data?",
            answer: "We prioritize data security. All customer data is encrypted and securely stored, accessible only by select few authorized personnel.",
            open: false
        },
        {
            question:
              "Is there support available for troubleshooting?",
            answer: "Absolutely! We offer 24/7 customer support to assist with any issues or questions you might have.",
            open: false
        }
      ]);
    
      const toggleFAQ = index => {
        setFaqs(
          faqs.map((faq, i) => {
            if (i === index) {
              faq.open = !faq.open;
            } else {
              faq.open = false;
            }
    
            return faq;
          })
        );
      };
    


    return (
        <div>
            <HomePageHeader/>
            <div className="p-0 w-full overflow-x-hidden">  

                <section className="flex flex-col gap-6 md:gap-0 align-items-start justify-center pt-2 px-12 md:flex-row w-full max-w-screen-xl my-0 mx-auto">
                    <div className=" flex flex-column font-main text-left md:w-1/2">
                        <h1 className='text-4xl font-bold py-2 md:text-5xl'>Simplify your waitlist management</h1>
                        <p className="font-main text-2xl py-2">Convert physical lineups to virtual ones</p>
                        <Link to='/signup'>
                            <button className='placeinq-button w-full md:w-2/4 mt-10 md:mt-16 md:my-2 py-3'>Sign Up</button>
                            {/* <button className='w-full md:w-2/4 mt-10 md:mt-16 md:my-2 py-3 px-3.5 border-[1.5px] text-black border-solid border-slate-400 rounded-xl shadow-signup text-2xl font-main font-medium 
                                        transition-transform hover:scale-[0.98] hover:shadow-signupInset border-placeinq'>Sign Up</button> */}
                        </Link>
                    </div>
                    <div className='w-full md:w-1/2'>
                        <img className='w-full' src="/homepage-images/invite.png" alt="Easy waitlist management" />                        
                    </div>
                </section>
                
                <div className='bg-slate-100 w-full'>
                <section className='px-12 py-12 text-black max-w-screen-xl my-0 mx-auto'>
                    <div className='flex flex-col md:flex-row justify-between items-start md:items-center gap-10 md:gap-0 lg:w-[90%] mx-auto'>
                        <div className='flex items-center md:flex-col justify-between gap-4'>
                            <div className='secondsection-item-emoji'>
                                <EmojiEmotionsIcon/>
                            </div>
                            <div className='text-left font-main text-2xl md:text-lg font-bold'>
                                <h3>Attract more customers</h3>
                            </div>
                        </div>
                        <div className='flex items-center md:flex-col justify-between gap-4'>
                            <div className='secondsection-item-emoji'>
                                <PaidIcon/>
                            </div>
                            <div className='text-left font-main text-2xl md:text-lg font-bold'>
                                <h3>Reduce staffing cost</h3>
                            </div>
                        </div>
                        <div className='flex items-center md:flex-col justify-between gap-4'>
                            <div className='secondsection-item-emoji'>
                                <HourglassTopIcon/>
                            </div>
                            <div className='text-left font-main text-2xl md:text-lg font-bold'>
                                <h3>Save time</h3>
                            </div>
                        </div>
                    </div>
                </section>
                </div>

                <section className="flex flex-col font-main px-12 pt-6 lg:w-[90%] w-full max-w-screen-xl my-0 mx-auto">
                    <div className="flex flex-col md:flex-row justify-between align-center py-6 gap-6">
                        <div className='font-main text-left w-full md:w-1/2 my-auto'>
                            <h3 className="font-main text-3xl font-bold">Scan a QR code to join the waitlist by entering:</h3>
                            <ul className='mt-3'>
                                <li className='font-main text-xl md:text-2xl mb-2'>1. Party size</li>
                                <li className='font-main text-xl md:text-2xl mb-2'>2. Phone number</li>
                                <li className='font-main text-xl md:text-2xl'>3. Name</li>
                            </ul>
                        </div>
                        <div className="mx-auto w-full md:w-1/2">
                            <HomeImageSlider/>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row-reverse justify-between align-center py-6 gap-6">
                        <div className='font-main text-left w-full md:w-1/2 my-auto'>
                            <h3 className="font-main text-3xl font-bold md:text-right">Watch your waitlist</h3>
                            <p className='font-main mt-3 text-xl md:text-2xl md:text-right'>Use placeinQ's dashboard to <strong>invite</strong> or <strong>remove</strong> customers from the waitlist</p>
                        </div>
                        <div className="mx-auto w-full md:w-1/2">
                            <img src="/homepage-images/dashboard1.png" alt="Dashboard" />                        
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between align-center pt-6 gap-6" >
                        <div className='font-main text-left w-full md:w-1/2 my-auto'>
                            <h3 className="font-main text-3xl font-bold">Notify customers by sending a text message</h3>
                            <p className='font-main mt-3 text-xl md:text-2xl'>A customizable <strong>text message</strong> to call your customers</p>
                        </div>
                        <div className="mx-auto w-full md:w-1/2">
                            <img src="/homepage-images/invitation-message-cropped.png" alt="Invitation-message" />                        
                        </div>
                    </div>
                </section>

                {/* <section className='Testimonials-section'>
                    <div className='Testimonial'>
                        <div className='Testimonial-content'>
                            <p>Incredible tool! Since we started using this waitlist management system, our customer flow has been smoother and more organized. It's easy to use and our guests appreciate the updates. Highly recommend!</p>
                        </div>
                        <div className='Testimonial-profile'>
                            <div className='Testimonial-profile-picture'>
                                <img src="/homepage-images/reviews/Jake.png" alt="Jake" />                        
                            </div>
                            
                            <div className='Testimonial-profile-name'>
                                <p>Jake Palmer</p>
                            </div>

                        </div>
                    </div>
                    <div className='Testimonial'>
                        <div className='Testimonial-content'>
                            <p>A game-changer for our small cafe! The ability to manage our waitlist virtually has reduced stress for both staff and customers. The text message feature is especially helpful in keeping everyone informed. Five stars!</p>
                        </div>
                        <div className='Testimonial-profile'>
                            <div className='Testimonial-profile-picture'>
                                <img src="/homepage-images/reviews/Mia.png" alt="Jake" />                        
                            </div>
                            
                            <div className='Testimonial-profile-name'>
                                <p>Mia Zhang</p>
                            </div>

                        </div>
                    </div>
                    <div className='Testimonial'>
                        <div className='Testimonial-content'>
                            <p>This platform has transformed how we handle busy periods. Inviting customers via text has not only improved our efficiency but also enhanced the customer experience. Definitely worth investing in.</p>
                        </div>
                        <div className='Testimonial-profile'>
                            <div className='Testimonial-profile-picture'>
                                <img src="/homepage-images/reviews/Luis.png" alt="Jake" />                        
                            </div>
                            
                            <div className='Testimonial-profile-name'>
                                <p>Luis Ortiz</p>
                            </div>
                        </div>
                    </div>
                </section> */}
                <div className='bg-placeinq w-full'>

                <section className='py-12 px-12 flex flex-col justify-between max-w-screen-xl my-0 mx-auto'>
                    <h1 className="font-main text-3xl font-bold text-white">Start using placeinQ</h1>
                    <Link to='/signup'>
                    <button className='bg-white w-full md:w-2/4 mt-10 md:mt-16 md:my-2 py-3 px-3.5 border-[1.5px] text-black border-solid border-slate-400 rounded-xl shadow-signup text-2xl font-main font-medium 
                                        transition-transform hover:scale-[0.98] hover:shadow-signupInset border-placeinq'>Sign Up</button>
                     </Link>
                </section>
                </div>

                <section className="faqs my-0 p-4 w-[90%] md:w-[70%] w-full max-w-screen-xl my-0 mx-auto">
                    <header className="flex justify-center align-center p-4 uppercase">
                        <h1 className='text-3xl font-main font-bold'>FAQ</h1>
                    </header>

                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </section>

            </div>
            <HomeFooter/>
        </div>
);
}

export default HomePage;
