import React,{useState, useEffect} from 'react'
import './ResetPasswordPage.css'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
// import { useStateValue } from '../StateManager/StateProvider';
import { API_URL } from '../../../EnvironmentVariables';
import Swal from 'sweetalert2';
import { GetUser } from '../../../App';

function ResetPasswordPage(props) {
    let { token } = useParams();

    const history = useNavigate();
    const location = useLocation();
    const [forgotpass_email,setForgotpass_email] = useState('');
    const [password,setPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');

    const [Massage, setMassage] = useState(location?.state?.massage ? location.state.massage : '');


    const SetNewPassword = (event, new_password) => {
        event.preventDefault();
        const url = `${API_URL}/api/password_reset/confirm/`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'password' : new_password,
                'token': token
            })
        })
        .then(resp => resp.json())
        .then(res => {
            console.log(res.password);
            if(res.status !== "OK"){
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to set new password',
                    text: res.password[0]
                  });            
            }else{
                Swal.fire({
                    icon: 'success',
                    title: 'New password was successfully set',
                  }); 
                history('/login');
 
            }
        })
        .catch(errors => console.log(errors));
    }


    return (

        <div className="login">
            <div className='login__logo'>
                <Link to='/'>
                    <img src="/new-logo.png" alt="Logo"/>
                </Link>
            </div>

            <div className="login__container">
            <h1>Forgot password?</h1>
            <h4>{Massage}</h4>
            <form>
                <label>New Password</label>
                <input 
                    type='password' 
                    minLength={8}
                    required={true}
                    value={password} 
                    onChange={e => setPassword(e.target.value)}
                />

                <label>Confirm New Password</label>
                <input 
                    type='password' 
                    minLength={8}
                    required={true}
                    value={confirmPassword} 
                    onChange={e => setConfirmPassword(e.target.value)}
                />
                {(password === confirmPassword || confirmPassword === '') ? null : <span className='input_alert'>Passwords do not match! <br/></span>}

                <button
                    disabled={!(password && confirmPassword && (confirmPassword === password))} 
                    type='submit' 
                    className="login__signInButton" 
                    onClick={(event) => SetNewPassword(event, password)}>Set new password
                </button>

            </form>

            <br/>


            </div>

        </div>
    )
}

export default ResetPasswordPage;